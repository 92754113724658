import Drawer, { DrawerProps } from '@mui/material/Drawer'

type SidebarDrawerProps = DrawerProps

const SidebarDrawer = ({ children, ...props }: SidebarDrawerProps) => {
  return (
    <Drawer anchor="left" className={props.className} {...props}>
      {children}
    </Drawer>
  )
}

export default SidebarDrawer
