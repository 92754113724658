"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_FILESIZE_MB = exports.MAX_FILESIZE_BYTES = exports.FILESIZE_KB = exports.FILESIZE_MB = void 0;
/**
 * Number of bytes in a Megabyte.
 */
exports.FILESIZE_MB = 1024 * 1024;
/**
 * Number of bytes in a Kilobyte.
 */
exports.FILESIZE_KB = 1024;
exports.MAX_FILESIZE_BYTES = 3 * exports.FILESIZE_MB;
exports.MAX_FILESIZE_MB = Math.round(exports.MAX_FILESIZE_BYTES / exports.FILESIZE_MB);
