import { useState } from 'react'
import { User } from 'react-feather'
import { AccountModal } from 'business/account/affiliate/AccountModal'

export const AccountButton = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <AccountModal
        key={String(isOpen)}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <div
        role="button"
        tabIndex={0}
        className="menu-item mt-auto"
        onClick={() => setIsOpen(true)}
      >
        <User size={24} /> <span>Account</span>
      </div>
    </>
  )
}
