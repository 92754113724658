import { useState } from 'react'
import { LogOut, ToggleLeft } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { SidebarAccountButton } from 'business/account/SidebarAccountButton'
import { Route } from 'config/routes/routes'
import { useAllowedUserRoutes } from 'config/routes/support'
import { useAuth } from 'services/auth.service'
import { WithRequired } from 'services/types/utils'
import { UserProfile } from 'shared/components/molecules/user/UserProfile'
import SidebarDrawer from 'shared/components/organisms/drawer/SidebarDrawer'
import { useMobileScreen } from 'shared/hooks/useMobileScreen'

type Props = {
  isDrawerOpen: boolean
  toggleDrawer: () => void
}

const Sidebar = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const [isSidebarMinimised, setIsSiderbarMinimised] = useState(false)

  const { logout, isLoggedIn } = useAuth()

  const routes = useAllowedUserRoutes()
  const isMobileScreen = useMobileScreen()

  const logoutHandler = async () => {
    toggleDrawer()
    logout()
  }

  return (
    <SidebarDrawer
      open={isDrawerOpen || !isMobileScreen}
      onClose={toggleDrawer}
      variant={isMobileScreen ? 'temporary' : 'permanent'}
      className={!isMobileScreen ? 'sidebar-drawer' : 'mobile-sidebar-drawer'}
    >
      <nav
        className={`nav-sidebar ${isSidebarMinimised && 'minimised'} ${isMobileScreen && 'mobile'}`}
      >
        <UserProfile />
        <div className="sidebar-menu flex flex-col gap-1">
          {routes
            .filter((route): route is WithRequired<Route, 'Icon'> =>
              Boolean(route.Icon)
            )
            .map(({ path, label, Icon }) => (
              <NavLink
                to={path}
                onClick={toggleDrawer}
                key={path}
                className={({ isActive }) =>
                  `menu-item ${isActive ? ' active' : ''}`
                }
              >
                <Icon size={24} /> <span>{label}</span>
              </NavLink>
            ))}
        </div>

        <div className="buttons-container">
          {isLoggedIn && (
            <div className="mt-4">
              <SidebarAccountButton />
              <div
                role="button"
                tabIndex={0}
                className="menu-item mt-auto"
                onClick={logoutHandler}
              >
                <LogOut size={24} /> <span>Logout</span>
              </div>
            </div>
          )}
          {isMobileScreen ? null : (
            <div
              role="button"
              tabIndex={0}
              className="collapse-button"
              onClick={() => setIsSiderbarMinimised(v => !v)}
            >
              <ToggleLeft size={24} />
            </div>
          )}
        </div>
      </nav>
    </SidebarDrawer>
  )
}

export default Sidebar
