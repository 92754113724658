import { MediaListItem } from '@packages/types'
import axios, { AxiosResponse } from 'axios'
import withToast from 'business/shared/utils/withToast'
import { baseURL } from 'config/constants'
import client from 'services/client'
import { usePaginated } from 'services/shared/usePaginated'
import {
  MediaCategory,
  MediaCreateRequestParams,
  MediaImpression,
  MediaItem
} from 'services/types/Media'
import { download } from 'shared/helpers/downloads/download'
import { SWRSuspenseConfiguration } from 'shared/types'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

export function useMediaItems() {
  const params = { search: '', tags: [] as string[], brands: [] as string[] }

  const { url, key, ...result } = usePaginated<MediaListItem, typeof params>(
    'media',
    { params, immutable: true }
  )

  const { trigger: create } = useSWRMutation<
    AxiosResponse<MediaListItem>,
    any,
    typeof key,
    MediaCreateRequestParams
  >(key, (_, { arg }) => {
    const fd = new FormData()

    Object.entries(arg as MediaCreateRequestParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => fd.append(`${key}[]`, v))
      } else {
        fd.append(key, typeof value === 'object' ? value : value?.toString())
      }
    })

    return client.post<MediaListItem>(url, fd, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  })

  return {
    ...result,
    create: (
      params: MediaCreateRequestParams,
      options?: Parameters<typeof create>[1]
    ) =>
      withToast(
        'Failed to create new Media Item',
        'Media item uploaded!'
      )(create)(params, options)
  }
}

export function useMedia(id: number | string) {
  const url = `media/${id}`

  const result = useSWR<MediaItem, typeof url, SWRSuspenseConfiguration>(url, {
    suspense: true
  })

  return {
    ...result,
    download: async () => {
      const resp = await client.get<string>(`${url}/download`)
      download(resp.data, `${result.data.name}.${result.data.extension}`)
    }
  }
}

export function useMediaCategories() {
  const result = useSWR<MediaCategory[], string, SWRSuspenseConfiguration>(
    'media/categories',
    { suspense: true }
  )

  return result
}

export function useMediaImpression(
  mediaToken: string,
  affiliateId: string | number
) {
  const url = `${baseURL}/impressions/media/${mediaToken}/${affiliateId}`

  const result = useSWR<MediaImpression, typeof url, SWRSuspenseConfiguration>(
    url,
    u =>
      axios
        .get(u, {
          headers: { 'Access-Control-Allow-Origin': '*' }
        })
        .then(response => response.data),
    { suspense: true }
  )

  return result
}
