type Classes =
  | string
  | { [val: string]: boolean | undefined }
  | null
  | undefined

/**
 * Build a valid component className.
 */
export function classNames(...classes: Classes[]): string {
  return classes
    .flatMap(entry => {
      if (entry && typeof entry === 'object') {
        return Object.entries(entry)
          .filter(([_, enabled]) => enabled)
          .map(([cls]) => cls)
      }

      if (typeof entry === 'string') {
        return entry.trim()
      }

      return ''
    })
    .filter(v => !!v)
    .join(' ')
}
