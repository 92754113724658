import { toast, ToastOptions } from 'react-toastify'

type Await<T extends (...args: any[]) => any> =
  ReturnType<T> extends Promise<infer R> ? R : ReturnType<T>

/**
 * HOF executing the given callback and displaying toast with type
 * based on the callback result
 * @param success Success Message
 * @param failure Failure message displayed if the given callback throws
 * @param options additional toast options
 */
const withToast =
  (failure: string, success?: string, options?: ToastOptions) =>
  <FN extends (...args: any[]) => Promise<any> | void>(fn: FN) =>
  async (...args: Parameters<FN>): Promise<Await<FN>> => {
    const r = await fn(...args)
    toast(success, {
      ...options,
      type: 'success'
    })
    return r
  }

export default withToast
