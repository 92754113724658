import { useEffect } from 'react'

/**
 * Set document (tab) title to the give value.
 */
export function useDocumentTitle(title: string) {
  useEffect(() => {
    const currentTitle = document.title
    document.title = title

    return () => {
      document.title = currentTitle
    }
  }, [title])
}
