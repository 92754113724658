import { InputItemsLoader } from '@coddess-development/coddess-ui'
import { ClickAwayListener } from '@mui/material'
import { AffiliateAccountLink } from 'business/shared/components/AffiliateAccountLink'
import { classNames } from 'shared/helpers/classnames/classNames'
import { createInfiniteScrollHandler } from 'shared/helpers/onScrollHelper'
import { Option } from 'shared/types'

type OwnProps = {
  options: (Option<string> | Option<number>)[]
  loading?: boolean
  onClose: () => void
  onScrollNearEnd: (numberOfCurrentItems: number) => any
}

const SearchResults = ({
  loading,
  options,
  onScrollNearEnd,
  onClose
}: OwnProps) => {
  const handleScrollNearEnd = createInfiniteScrollHandler(() =>
    onScrollNearEnd?.(options ? options.length : 0)
  )

  return (
    <div className="mobile-searchbar-container">
      <ClickAwayListener onClickAway={onClose}>
        <ul
          className={classNames(
            'autocomplete-dropdown mobile-searchbar-dropdown active'
          )}
          onScroll={loading ? undefined : handleScrollNearEnd}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {!loading && options.length > 0 ? (
            <>
              {options.map(option => (
                <li
                  className="autocomplete-option"
                  key={`${option.value}-option-${option.label}`}
                >
                  <AffiliateAccountLink
                    id={option.value}
                    username={option.label}
                    onClick={onClose}
                    className="w-full"
                  />
                </li>
              ))}
            </>
          ) : loading ? (
            <div className="flex flex-center">
              <InputItemsLoader loading={loading} className="w-full" />
            </div>
          ) : (
            <li className="autocomplete-option disabled">
              <button
                type="button"
                onClick={onClose}
                className="w-full flex-start"
              >
                No results
              </button>
            </li>
          )}
        </ul>
      </ClickAwayListener>
    </div>
  )
}

export default SearchResults
