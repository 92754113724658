"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiskScore = void 0;
/**
 * Score for different levels of Risk.
 */
var RiskScore;
(function (RiskScore) {
    RiskScore[RiskScore["Low"] = 1] = "Low";
    RiskScore[RiskScore["Medium"] = 3] = "Medium";
    RiskScore[RiskScore["High"] = 8] = "High";
})(RiskScore || (exports.RiskScore = RiskScore = {}));
