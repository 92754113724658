import {
  Form as CoddessForm,
  FormProps as CoddessFormProps
} from '@coddess-development/coddess-ui'
import { HttpStatus } from '@packages/constants'
import axios from 'axios'
import { FormikErrors } from 'formik'
import { UnprocessableEntityResponse } from 'services/types/General'

export type FormProps<T extends object> = CoddessFormProps<T>

export const Form = <T extends object>({
  children,
  onSubmit,
  ...props
}: FormProps<T>) => {
  return (
    <CoddessForm<T>
      {...props}
      // The className is added as it is the only selector currently able to detect the form
      className="form"
      onSubmit={async (vals, helpers) => {
        try {
          await onSubmit(vals, helpers)
        } catch (err) {
          if (
            axios.isAxiosError(err) &&
            err.response &&
            err.response.status === HttpStatus.UnprocessableEntity
          ) {
            const formErrors: FormikErrors<T> = {}

            const { errors } = err.response
              .data as UnprocessableEntityResponse<T>
            errors.forEach(({ field, message }) => {
              formErrors[field] = message as any
            })

            helpers.setErrors(formErrors)
          }

          throw err
        }
      }}
    >
      {children}
    </CoddessForm>
  )
}
