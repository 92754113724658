import { toast } from 'react-toastify'
import {
  AffiliateInsiderCountryRow,
  AffiliateRankStanding,
  HighlightProps,
  ResponseWithMessage
} from '@packages/types'
import { AxiosResponse } from 'axios'
import useRoles from 'business/shared/utils/Permission/useRoles'
import withToast from 'business/shared/utils/withToast'
import { DateTime } from 'luxon'
import { usePaymentRequests } from 'services/financials.service'
import {
  InfiniteOptionsConfig,
  InfiniteOptionsInitialValue,
  InfiniteOptionsOptionValueType,
  useInfiniteOptions
} from 'services/shared/useInfiniteOptions'
import { usePaginated, UsePaginatedOptions } from 'services/shared/usePaginated'
import { HistoryRow } from 'services/types/AccountHistory'
import { DashboardMonthlyComparisonValue } from 'services/types/Dashboard'
import User from 'services/types/User'
import {
  LongDedupingInterval,
  MediumDedupingInterval
} from 'shared/SWRConfiguration'
import { Option, SWRFetcherKey, SWRSuspenseConfiguration } from 'shared/types'
import useSWR, { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'

import Affiliate, {
  AffiliateApprovalParams,
  AffiliatePostbackCreateRequestParams,
  AffiliatePostbackTestRequestParams,
  AffiliatePostbackUpdateRequestParams,
  AffiliatesAssignedToMasterAffiliateResponse,
  CreateBalanceCorrectionRequestParams,
  GrantMasterAffiliateRoleRequestParams,
  MasterAffiliateHighlightsResponse
} from './types/Affiliate'
import { PostbackTestResult, PostbackUrl } from './types/Postback'
import client from './client'
import {
  suspend as suspendUser,
  unsuspend as unsuspendUser
} from './users.service'

export const useApprovedAffiliatesList = () => {
  const { approve, ...result } = useAffiliateLists('approved')
  return result
}

export const useWaitingAffiliatesList = () => {
  const { create, ...result } = useAffiliateLists('waiting')
  return result
}

export const useRejectedAffiliatesList = () => {
  const { create, reject, ...result } = useAffiliateLists('rejected')
  return result
}
export const useSuspendedAffiliatesList = () => {
  const { create, approve, reject, ...result } = useAffiliateLists('suspended')
  return result
}

function useAffiliateLists(
  type: 'approved' | 'rejected' | 'waiting' | 'suspended'
) {
  const params = { search: '', type }
  const { key, ...result } = usePaginated<Affiliate, typeof params>(
    '/affiliates',
    { params }
  )

  const { trigger: create } = useSWRMutation<
    AxiosResponse<ResponseWithMessage<Affiliate>>,
    any,
    typeof key,
    Affiliate
  >(key, async (_, { arg }) => {
    const affiliateDetails = {
      ...arg,
      company: {
        agreedToTerms: DateTime.now().toUTC().toString(),
        receiveUpdates: null,
        receivePromotionalMaterials: null
      }
    }

    const response = await client.post(`/affiliates`, affiliateDetails)

    toast(response.data.message, { type: 'success' })

    return response
  })

  const { trigger: reject } = useSWRMutation<
    AxiosResponse<ResponseWithMessage<Affiliate>>,
    any,
    typeof key,
    { affiliateId: number }
  >(key, async (_, { arg: { affiliateId } }) => {
    return client.put(`/affiliates/${affiliateId}/reject`)
  })

  const { trigger: approve } = useSWRMutation<
    AxiosResponse<ResponseWithMessage<Affiliate>>,
    any,
    typeof key,
    AffiliateApprovalParams
  >(key, async (_, { arg: { affiliateId, managers } }) => {
    return client.put(`/affiliates/${affiliateId}/approve`, managers)
  })

  return {
    ...result,
    reject: (affiliateId: number) =>
      withToast('', 'Affiliate rejected!')(reject)({ affiliateId }),
    approve: (
      affiliateId: number,
      managers: { firstManagerId: number; secondManagerId?: number }
    ) =>
      withToast('', 'Affiliate approved!')(approve)({ affiliateId, managers }),
    create: (params: any, options?: Parameters<typeof create>[1]) =>
      create(params, options)
  }
}

export function useAffiliate(
  id: string | number,
  config?: Omit<SWRSuspenseConfiguration, 'suspense'>
) {
  const url = `/affiliates/${id}`
  const key: SWRFetcherKey = url

  const result = useSWR<Affiliate, string, SWRSuspenseConfiguration>(key, {
    suspense: true,
    ...config
  })

  const { trigger: update } = useSWRMutation<
    Affiliate,
    any,
    typeof key,
    { [key: string]: any }
  >(key, async (_, { arg }) => {
    const res = await client.put(`/affiliates/${id}`, arg)

    toast('Affiliate details were updated!', { type: 'success' })

    return res.data
  })

  const { trigger: suspend } = useSWRMutation<
    Affiliate,
    any,
    typeof key,
    string
  >(key, async (_, { arg: reason }) => {
    const res = await suspendUser(id, reason)

    toast('Affiliate was suspended!', { type: 'success' })

    return res.data
  })

  const { trigger: unsuspend } = useSWRMutation<
    Affiliate,
    any,
    typeof key,
    any
  >(key, async () => {
    const res = await unsuspendUser(id)

    toast('Affiliate was unsuspended!', { type: 'success' })

    return res.data
  })

  return {
    ...result,
    update: (
      params: { [key: string]: any },
      config?: Parameters<typeof update>[1]
    ) => {
      return update(params, config)
    },
    suspend: async (reason: string, config?: Parameters<typeof suspend>[1]) => {
      return suspend(reason, config)
    },
    unsuspend: async (config?: Parameters<typeof unsuspend>[1]) => {
      return unsuspend(undefined, config)
    }
  }
}

export function useAffiliateAccountHistory(
  affiliateId: number,
  config?: UsePaginatedOptions<HistoryRow>
) {
  return usePaginated<HistoryRow>(`/histories/affiliate/${affiliateId}`, config)
}

export function useAffiliateBalanceCorrections(
  affiliateId: number | string,
  config?: Omit<
    Parameters<typeof usePaymentRequests>[0],
    'status' | 'affiliateId'
  >
) {
  const balance = useSWR<number, string, SWRSuspenseConfiguration>(
    `/affiliates/${affiliateId}/balance`,
    { suspense: true }
  )
  const balanceCorrections = usePaymentRequests({
    affiliateId,
    status: ['credit', 'debit'],
    ...config
  })

  const { trigger: create } = useSWRMutation<
    void,
    any,
    typeof balanceCorrections.key,
    CreateBalanceCorrectionRequestParams
  >(balanceCorrections.key, async (_, { arg }) => {
    await client.post<void>(
      `/affiliates/${affiliateId}/balance-correction`,
      arg
    )
    await balance.mutate()
  })

  return {
    ...balanceCorrections,
    balance: balance.data,
    create: (
      params: CreateBalanceCorrectionRequestParams,
      config?: Parameters<typeof create>[1]
    ) => {
      return create(params, config)
    }
  }
}

/**
 * Returns an 'infinite' list of all Affiliate users.
 */
export function useAffiliatesOptions<
  I extends InfiniteOptionsInitialValue = string,
  O extends InfiniteOptionsOptionValueType = number
>(config?: InfiniteOptionsConfig<Affiliate, I, O>) {
  return useInfiniteOptions('/affiliate-user-options', {
    toOption: affiliate =>
      ({ label: affiliate.username, value: affiliate.id }) as Option<O>,
    dedupingInterval: MediumDedupingInterval,
    ...config
  })
}

/**
 * Returns an 'infinite' list of all affiliate managers.
 */
export function useManagersOptions<
  I extends InfiniteOptionsInitialValue,
  O extends InfiniteOptionsOptionValueType = number
>(config?: InfiniteOptionsConfig<Affiliate, I, O>) {
  return useInfiniteOptions('/management-user-options', {
    toOption: manager =>
      ({ label: manager.username, value: manager.id }) as Option<O>,
    dedupingInterval: LongDedupingInterval,
    ...config
  })
}

/**
 * Returns an 'infinite' list of all affiliate responsible users (managers).
 */
export function useAffiliateResponsibleUsersOptions<
  I extends InfiniteOptionsInitialValue,
  O extends InfiniteOptionsOptionValueType = number
>(config?: InfiniteOptionsConfig<Affiliate, I, O>) {
  return useInfiniteOptions('/affiliate-responsible-users', {
    toOption: user => ({ label: user.username, value: user.id }) as Option<O>,
    dedupingInterval: LongDedupingInterval,
    ...config
  })
}

export function useMasterAffiliateDashboard(affiliateId: number | string) {
  const { data: affiliate, mutate: mutateAffiliate } = useAffiliate(affiliateId)

  const is = useRoles(affiliate)
  const isMasterAffiliate = is('master_affiliate')

  const url = `/affiliates/${affiliate.id}/master`
  const params = { search: '' }

  const { key, ...affiliateListResult } = usePaginated<
    AffiliatesAssignedToMasterAffiliateResponse,
    typeof params,
    true
  >(isMasterAffiliate ? url : null, { params })

  const { trigger: assign } = useSWRMutation<
    AxiosResponse<void>,
    any,
    typeof key,
    number
  >(key, (_, { arg }) => {
    return client.put<void>(`${url}/${arg}`)
  })
  const { trigger: unassign } = useSWRMutation<
    AxiosResponse<void>,
    any,
    typeof key,
    number
  >(key, (_, { arg }) => {
    return client.delete<void>(`${url}/${arg}`)
  })

  return {
    affiliate,
    isMasterAffiliate,

    affiliateList: {
      ...affiliateListResult
    },

    /**
     * Send a request to assign an affiliate to the master affiliate.
     */
    assignAffiliate: (
      affiliateId: number,
      options?: Parameters<typeof assign>[1]
    ) => withToast('', 'Affiliate Assigned!')(assign)(affiliateId, options),
    /**
     * Send a request to unassign an affiliate from the master affiliate.
     */
    unassignAffiliate: (
      affiliateId: number,
      options?: Parameters<typeof unassign>[1]
    ) => withToast('', 'Affiliate Unassigned!')(unassign)(affiliateId, options),
    /**
     * Send request to make an Affiliate use a Master Affiliate.
     */
    grantMasterAffiliateRole: async (
      data: GrantMasterAffiliateRoleRequestParams
    ) => {
      const res = await client.post<Affiliate>(url, data)
      await mutateAffiliate()

      return res.data
    }
  }
}

export function useMasterAffiliateAssignableAffiliatesOptions(
  affiliateId: number | string
) {
  return useInfiniteOptions<Pick<Required<User>, 'id' | 'username'>>(
    `/affiliates/${affiliateId}/master/affiliates`
  )
}

export function useMasterAffiliateHighlights(affiliateId: number | string) {
  const result = useSWR<
    MasterAffiliateHighlightsResponse,
    string,
    SWRSuspenseConfiguration
  >(`/affiliates/${affiliateId}/master/highlights`, {
    suspense: true
  })

  return result
}

export function useAffiliateInsiderMonthlyComparison(affiliateId: number) {
  const result = useSWR<
    DashboardMonthlyComparisonValue[],
    string,
    SWRSuspenseConfiguration
  >(`/affiliates/${affiliateId}/affiliate-insider/monthly-comparison/`, {
    suspense: true
  })

  return result
}

export function useAffiliateInsiderHighlights(affiliateId: number | string) {
  const result = useSWR<HighlightProps[], string, SWRSuspenseConfiguration>(
    `/affiliates/${affiliateId}/affiliate-insider/monthly-highlights/`,
    {
      suspense: true
    }
  )

  return result
}

export function useAffiliateInsiderCountries(affiliateId: number | string) {
  const result = useSWR<
    AffiliateInsiderCountryRow[],
    string,
    SWRSuspenseConfiguration
  >(`/affiliates/${affiliateId}/affiliate-insider/countries-overview/`, {
    suspense: true
  })

  return result
}

export function useAffiliatePostback(
  affiliateId: number | string,
  config?: Omit<UsePaginatedOptions<PostbackUrl>, 'suspense' | 'params'>
) {
  const result = usePaginated<PostbackUrl>(
    `/affiliates/${affiliateId}/postback-links`,
    config
  )

  const { trigger: create } = useSWRMutation<
    PostbackUrl,
    any,
    typeof result.key,
    AffiliatePostbackCreateRequestParams
  >(result.key, async (_, { arg }) => {
    const response = await client.post<ResponseWithMessage<PostbackUrl>>(
      `/affiliates/${affiliateId}/postback-links`,
      arg
    )

    toast(response.data.message, { type: 'success' })

    return response.data.data
  })

  const { trigger: update } = useSWRMutation<
    PostbackUrl,
    any,
    typeof result.key,
    AffiliatePostbackUpdateRequestParams
  >(result.key, async (_, { arg: { id, ...params } }) => {
    const response = await client.put<PostbackUrl>(
      `postback-links/${id}`,
      params
    )

    toast('Postback URL updated!', { type: 'success' })

    return response.data
  })

  const { trigger: destroy } = useSWRMutation<
    void,
    any,
    typeof result.key,
    PostbackUrl['id']
  >(result.key, async (_, { arg: id }) => {
    const response = await client.delete<ResponseWithMessage<void>>(
      `postback-links/${id}`
    )

    toast(response.data.message, { type: 'success' })
  })

  const { trigger: test } = useSWRMutation<
    PostbackTestResult,
    any,
    typeof result.key,
    AffiliatePostbackTestRequestParams
  >(result.key, async (_, { arg }) => {
    const response = await client.post<{ status: PostbackTestResult }>(
      `/affiliates/${affiliateId}/postback-links/test`,
      arg
    )

    return response.data.status
  })

  return {
    ...result,
    create: (
      params: AffiliatePostbackCreateRequestParams,
      config?: Parameters<typeof create>[1]
    ) => {
      return create(params, config)
    },
    update: (
      params: AffiliatePostbackUpdateRequestParams,
      config?: Parameters<typeof update>[1]
    ) => {
      return update(params, config)
    },
    destroy: (
      id: PostbackUrl['id'],
      config?: Parameters<typeof destroy>[1]
    ) => {
      return destroy(id, config)
    },
    test: (
      params: AffiliatePostbackTestRequestParams,
      config?: Parameters<typeof test>[1]
    ) => {
      return test(params, config)
    }
  }
}

export function useAffiliateRankStanding(
  affiliateId: number | string,
  config?: SWRConfiguration<AffiliateRankStanding>
) {
  return useSWRImmutable<AffiliateRankStanding>(
    `/affiliates/${affiliateId}/rank-standing`,
    { suspense: true, ...config }
  )
}

export function useOwnPostback(
  config?: Omit<UsePaginatedOptions<PostbackUrl>, 'suspense' | 'params'>
) {
  const url = '/affiliate/postback-links'

  const result = usePaginated<PostbackUrl>(url, { immutable: true, ...config })

  const { trigger: create } = useSWRMutation<
    PostbackUrl,
    any,
    typeof result.key,
    AffiliatePostbackCreateRequestParams
  >(result.key, async (_, { arg }) => {
    const response = await client.post<ResponseWithMessage<PostbackUrl>>(
      url,
      arg
    )

    toast(response.data.message, { type: 'success' })

    return response.data.data
  })

  const { trigger: update } = useSWRMutation<
    PostbackUrl,
    any,
    typeof result.key,
    AffiliatePostbackUpdateRequestParams
  >(result.key, async (_, { arg: { id, ...params } }) => {
    const response = await client.put<PostbackUrl>(`${url}/${id}`, params)

    toast('Postback URL updated!', { type: 'success' })

    return response.data
  })

  const { trigger: destroy } = useSWRMutation<
    void,
    any,
    typeof result.key,
    PostbackUrl['id']
  >(result.key, async (_, { arg: id }) => {
    const response = await client.delete<ResponseWithMessage<void>>(
      `${url}/${id}`
    )

    toast(response.data.message, { type: 'success' })
  })

  const { trigger: test } = useSWRMutation<
    PostbackTestResult,
    any,
    typeof result.key,
    AffiliatePostbackTestRequestParams
  >(result.key, async (_, { arg }) => {
    const response = await client.post<{ status: PostbackTestResult }>(
      `${url}/test`,
      arg
    )

    return response.data.status
  })

  return {
    ...result,
    create: (
      params: AffiliatePostbackCreateRequestParams,
      config?: Parameters<typeof create>[1]
    ) => {
      return create(params, config)
    },
    update: (
      params: AffiliatePostbackUpdateRequestParams,
      config?: Parameters<typeof update>[1]
    ) => {
      return update(params, config)
    },
    destroy: (
      id: PostbackUrl['id'],
      config?: Parameters<typeof destroy>[1]
    ) => {
      return destroy(id, config)
    },
    test: (
      params: AffiliatePostbackTestRequestParams,
      config?: Parameters<typeof test>[1]
    ) => {
      return test(params, config)
    }
  }
}
