import { useAuth } from 'services/auth.service'

import { PermissionSlug } from '../../../../services/types/Permission'

type Has = (...permissionSlugs: PermissionSlug[]) => boolean

const usePermissions = (): Has => {
  const { maybeUser } = useAuth()

  return (...permissionSlugs) =>
    Boolean(
      maybeUser?.permissions?.some(({ slug }) =>
        permissionSlugs.some(s => s === slug)
      )
    )
}

export default usePermissions
