import { useEffect, useState } from 'react'
import { InputText } from '@coddess-development/coddess-ui'
import SearchResults from 'shared/components/organisms/mobile-navbar/SearchResults'
import { Option } from 'shared/types'

type OwnProps = {
  options: (Option<string> | Option<number>)[]
  loading: boolean
  onScrollNearEnd: (numberOfCurrentItems: number) => any
  onChange: (search: string) => void
}

const Searchbar = ({
  loading,
  options,
  onScrollNearEnd,
  onChange
}: OwnProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(loading)
  }, [loading, options])

  return (
    <>
      {searchValue ? (
        <SearchResults
          loading={isLoading}
          options={options}
          onClose={() => {
            setSearchValue('')
            onChange('')
          }}
          onScrollNearEnd={onScrollNearEnd}
        />
      ) : null}
      <InputText
        name="mobile-searchbar"
        role="searchbar"
        icon="Search"
        onChange={search => {
          setIsLoading(true)
          setSearchValue(search)
          onChange(search)
        }}
        value={searchValue}
        placeholder="Search"
        className="w-3/4"
      />
    </>
  )
}

export default Searchbar
