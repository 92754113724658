import { ReactElement } from 'react'
import { ArrowLeft } from 'react-feather'
import { Button, ModalContent } from '@coddess-development/coddess-ui'
import { OTP_CODE_LENGTH } from '@packages/constants'
import {
  TwoFactorAuthenticationCodeContainerId,
  TwoFactorAuthenticationCodeInput
} from 'business/shared/2fa/TwoFactorAuthenticationCodeInput'
import { useAuth } from 'services/auth.service'
import { Modal } from 'shared/components/molecules/modal/Modal'
import { Form } from 'shared/components/organisms/forms/form/Form'
import { FormSubmitButton } from 'shared/components/organisms/forms/submit-button/FormSubmitButton'

type Props = {
  children: ReactElement
}

export const TwoFactorAuthentication = ({ children }: Props) => {
  const {
    isVerifyingToken,
    verifyToken,
    requiresTwoFactorAuthentication,
    logout
  } = useAuth()

  if (!requiresTwoFactorAuthentication) {
    return children
  }

  const getCode = () => {
    const inputs = document
      .getElementById(TwoFactorAuthenticationCodeContainerId)
      ?.querySelectorAll('input')

    if (inputs) {
      return Array.from(inputs)
        .map(input => input.value)
        .join('')
    }

    return ''
  }

  return (
    <Modal
      open
      size="sm"
      title="Check your email for Login verification code"
      loading={isVerifyingToken}
    >
      <Form
        initialValues={{}}
        onSubmit={async () => {
          const code = getCode()

          if (code.length === OTP_CODE_LENGTH) {
            await verifyToken(code)
          }
        }}
      >
        <ModalContent>
          <div className="flex flex-col align-center gap-8">
            <TwoFactorAuthenticationCodeInput />
            <div className="flex flex-row justify-between w-full">
              <Button
                iconPlacement="left"
                variant="secondary"
                icon={<ArrowLeft />}
                onClick={logout}
              >
                Back to Login
              </Button>
              <FormSubmitButton variant="primary">Verify Code</FormSubmitButton>
            </div>
          </div>
        </ModalContent>
      </Form>
    </Modal>
  )
}
