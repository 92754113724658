import { Link, LinkProps } from 'react-router-dom'
import usePermissions from 'business/shared/utils/Permission/usePermissions'
import { classNames } from 'shared/helpers/classnames/classNames'

type AffiliateAccountLinkProps = {
  username: string
  id: number | string
  className?: string
} & Omit<LinkProps, 'to' | 'id'>

export const AffiliateAccountLink = ({
  id,
  username,
  className,
  ...props
}: AffiliateAccountLinkProps) => {
  const can = usePermissions()

  if (!can('view-affiliate')) {
    return <span>{username}</span>
  }

  const classes = classNames('table-link', className)

  return (
    <Link className={classes} to={`/account/${id}`} {...props}>
      {username}
    </Link>
  )
}
