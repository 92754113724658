import { toast } from 'react-toastify'
import { ModalContent, ModalFooter } from '@coddess-development/coddess-ui'
import { AccountModalFormFields } from 'business/account/affiliate/AccountModalFormFields'
import { useAuth } from 'services/auth.service'
import { useCountriesOptions } from 'services/countries.service'
import { UpdateCurrentUserParams } from 'services/types/Auth'
import { Form } from 'shared/components/organisms/forms/form/Form'
import { FormResetButton } from 'shared/components/organisms/forms/reset-button/FormResetButton'
import { FormSubmitButton } from 'shared/components/organisms/forms/submit-button/FormSubmitButton'
import { extendedWebsiteWithPlaceholder } from 'shared/validations'
import * as Yup from 'yup'

type Props = {
  onClose?: () => void
}

export const AccountModalContent = ({ onClose }: Props) => {
  const { user, update } = useAuth()

  const countries = useCountriesOptions({
    initial: user.affiliateProfile?.country?.name,
    suspense: true
  })

  return (
    <Form
      initialValues={{
        ...user,
        enableTwoFactorAuth: user.twoFactorAuthMethod === 'email',
        affiliateProfile: {
          ...user.affiliateProfile,
          countryId: countries.initialOption
        },
        password: ''
      }}
      validationSchema={Yup.object({
        affiliateProfile: Yup.object({
          website: extendedWebsiteWithPlaceholder.required(
            'Enter a valid website URL'
          )
        })
      })}
      onSubmit={async values => {
        const params: UpdateCurrentUserParams = {
          ...values,
          affiliateProfile: {
            ...values.affiliateProfile!,
            countryId: values.affiliateProfile!.countryId!.value
          }
        }

        await update(params)
        toast('Account changes saved!', { type: 'success' })

        onClose?.()
      }}
    >
      <ModalContent noOverflow>
        <AccountModalFormFields />
      </ModalContent>
      <ModalFooter className="flex flex-row justify-between">
        <FormResetButton>Reset Changes</FormResetButton>
        <FormSubmitButton variant="primary">Update Account</FormSubmitButton>
      </ModalFooter>
    </Form>
  )
}
