import { ForwardedRef, forwardRef } from 'react'
import {
  Autocomplete,
  AutocompleteProps
} from '@coddess-development/coddess-ui'
import { useField } from 'formik'

import { Option } from '../../../../types'

export type FormAutocompleteProps<
  T extends Option<any>,
  M extends boolean
> = Omit<AutocompleteProps<T, M>, 'value' | 'invalid'>

export const FormAutocomplete = forwardRef(
  <T extends Option<any>, M extends boolean>(
    { defaultValue, name, onChange, ...props }: FormAutocompleteProps<T, M>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [{ value }, { error, touched }, { setTouched, setValue }] = useField({
      name,
      value: defaultValue as any
    })

    return (
      <div>
        <Autocomplete<T, M>
          invalid={touched && !!error}
          defaultValue={defaultValue}
          name={name}
          value={value}
          onChange={(event, values, reason, details) => {
            // Formik is meant to be used with onChange and be passed a valid InputEvent
            // to automatically update the touched state otherwise. Thus we have to update
            // the touch state manually.
            setTouched(true)

            setValue(values)
            onChange?.(event, values, reason, details)
          }}
          {...props}
          ref={ref}
        />
        <div className="error">{touched && error}</div>
      </div>
    )
  }
)
