import { InputText } from '@coddess-development/coddess-ui'
import { TwoFactorAuthenticationCodeContainerId } from 'business/shared/2fa/TwoFactorAuthenticationCodeInput'
import { useFormikContext } from 'formik'

type Props = {
  index: number
}

export const TwoFactorAuthenticationNumberInput = ({ index }: Props) => {
  const { submitForm } = useFormikContext()

  return (
    <InputText
      name={`number-${index}`}
      className="two-factor-authentication-input"
      onClick={e => (e.target as HTMLInputElement).select()}
      onFocus={e => e.target.select()}
      onPaste={e => {
        e.preventDefault()
        const pastedData = e.clipboardData.getData('text/plain')
        const numbers = pastedData.match(/\d/g)

        if (numbers) {
          const inputs = document
            .getElementById(TwoFactorAuthenticationCodeContainerId)
            ?.querySelectorAll('input')

          if (inputs) {
            for (let i = 0; i < inputs.length; i += 1) {
              inputs[i].value = numbers[i] || ''
            }

            submitForm()
          }
        }
      }}
      onChange={(value, e) => {
        if (e) {
          // We only want to allow one symbol, and it should be a number.
          e.target.value = value.match(/\d/)?.[0].slice(0, 1) || ''

          // If we've successfully input a number, advanced to the next input in line.
          if (e.target.value) {
            const inputs = document
              .getElementById(TwoFactorAuthenticationCodeContainerId)
              ?.querySelectorAll('input')

            if (inputs) {
              const nextInput = inputs[index + 1]

              if (nextInput) {
                nextInput.focus()
              } else {
                submitForm()
              }
            }
          }
        }
      }}
    />
  )
}
