import { AxiosRequestHeaders } from 'axios'
import { AuthToken } from 'services/auth.service'

export const baseURL = process.env.REACT_APP_BACKEND_URL || '/api'

export const config = (headers?: AxiosRequestHeaders) => {
  const auth = AuthToken.get()

  return {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers,
      ...(auth && { Authorization: `Bearer ${auth.token}` })
    }
  }
}
