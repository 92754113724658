import {
  InfiniteOptionsConfig,
  InfiniteOptionsInitialValue,
  InfiniteOptionsOptionValueType,
  useInfiniteOptions
} from 'services/shared/useInfiniteOptions'
import { StaticDedupingInterval } from 'shared/SWRConfiguration'
import { Option } from 'shared/types'

import Country from './types/Country'

/**
 * Returns an 'infinite' list of countries.
 */
export function useCountriesOptions<
  I extends InfiniteOptionsInitialValue,
  O extends InfiniteOptionsOptionValueType = number
>({
  geoTargetable = false,
  ...config
}: InfiniteOptionsConfig<Country, I, O> & { geoTargetable?: boolean } = {}) {
  return useInfiniteOptions('/countries', {
    dedupingInterval: StaticDedupingInterval,
    toOption: (country: Country) =>
      ({
        value: country.id,
        label:
          geoTargetable && country.name.toLowerCase() === 'all countries'
            ? 'GEO Targeted'
            : country.name
      }) as Option<O>,
    ...config
  })
}
