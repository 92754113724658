import {
  Button,
  ModalContent,
  ModalFooter,
  ModalProps
} from '@coddess-development/coddess-ui'
import { DateTime } from 'luxon'
import { useAuth } from 'services/auth.service'
import { useTermsAndConditions } from 'services/platform-configuration.service'
import { Modal } from 'shared/components/molecules/modal/Modal'
import { Form } from 'shared/components/organisms/forms/form/Form'
import { FormSubmitButton } from 'shared/components/organisms/forms/submit-button/FormSubmitButton'

type Props = Omit<ModalProps, 'children' | 'open'>

export const TermsAndConditionsAgreementModal = (props: Props) => {
  const { data: termsAndConditions } = useTermsAndConditions()
  const { logout, update } = useAuth()

  return (
    <Modal {...props} open size="md" title="Terms & Conditions">
      <Form
        initialValues={{ file: null as any }}
        onSubmit={async () => {
          await update({
            affiliateProfile: {
              agreedToTerms: DateTime.now().toISO() || undefined
            }
          })
        }}
      >
        <ModalContent noOverflow>
          <div className="flex flex-col gap-4">
            <Button
              variant="secondary"
              onClick={() => window.open(termsAndConditions)}
            >
              View file in separate tab
            </Button>
            <embed src={termsAndConditions} width="100%" height="360px" />
          </div>
        </ModalContent>
        <ModalFooter className="flex flex-row justify-between">
          <Button variant="secondary" onClick={logout}>
            Decline
          </Button>
          <FormSubmitButton variant="primary" className="ml-auto">
            Accept Terms & Conditions
          </FormSubmitButton>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
