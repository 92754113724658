import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppThemeProvider } from 'AppThemeProvider'
import { SuspenseBoundary } from 'shared/screens/SuspenseBoundary'
import { AppSWRConfig } from 'shared/SWRConfiguration'

import 'react-toastify/dist/ReactToastify.css'
import './assets/styles/base.scss'

type Props = {
  children: ReactNode
}

export const Kernel = ({ children }: Props) => {
  return (
    <BrowserRouter>
      <AppSWRConfig>
        <AppThemeProvider>
          <SuspenseBoundary>{children}</SuspenseBoundary>
        </AppThemeProvider>
      </AppSWRConfig>
    </BrowserRouter>
  )
}
