import { forwardRef } from 'react'
import { Button, ButtonProps } from '@coddess-development/coddess-ui'
import { useFormikContext } from 'formik'

type OwnProps = {
  /**
   * Whether button is disabled on invalid form.
   */
  disableOnInvalid?: boolean
}

export type FormSubmitButtonProps = OwnProps &
  Omit<ButtonProps, 'label' | keyof OwnProps>

export const FormResetButton = forwardRef<
  HTMLButtonElement,
  FormSubmitButtonProps
>(
  (
    { children = '', disabled, onClick, variant = 'secondary', ...props },
    ref
  ) => {
    const { isSubmitting, resetForm } = useFormikContext()

    return (
      <Button
        {...props}
        variant={variant}
        onClick={() => {
          resetForm()

          onClick?.()
        }}
        disabled={disabled || isSubmitting}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)
