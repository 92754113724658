import { useAffiliatesOptions } from 'services/affiliate.service'
import Searchbar from 'shared/components/organisms/mobile-navbar/Searchbar'

export const MobileSearch = () => {
  const affiliates = useAffiliatesOptions()

  return (
    <Searchbar
      loading={affiliates.isLoading}
      options={affiliates.options}
      onScrollNearEnd={affiliates.loadMore}
      onChange={affiliates.search}
    />
  )
}
