import { lazy } from 'react'
import NoAccessPermissionPage from 'shared/screens/NoAccessPermissionPage'
import NotFoundPage from 'shared/screens/NotFoundPage'

const Screens = {
  AffiliateDashboard: lazy(
    () => import('business/dashboard/affiliate/AffiliateDashboard')
  ),
  Management: lazy(() => import('business/management/Management')),
  Affiliates: lazy(() => import('business/affiliates/Affiliates')),
  Reports: lazy(() => import('business/reports/Reports')),
  Marketing: lazy(() => import('business/marketing/Marketing')),
  Configuration: lazy(() => import('business/configuration/Configuration')),
  Tools: lazy(() => import('business/tools/Tools')),
  AccountOverview: lazy(
    () => import('business/accountOverview/AccountOverview')
  ),
  Showcase: lazy(() => import('./documentation/Documentation')),
  Login: lazy(() => import('business/auth/login/Login')),
  Register: lazy(() => import('business/auth/register/Register')),
  ForgottenPassword: lazy(
    () => import('business/auth/reset-password/ForgottenPassword')
  ),
  ResetPassword: lazy(
    () => import('business/auth/reset-password/ResetPassword')
  ),
  Financials: lazy(() => import('business/financials/Financials')),
  Newton: lazy(() => import('business/newton/Newton')),
  MasterAffiliateDashboard: lazy(
    () => import('business/master/MasterAffiliateDashboard')
  ),
  Contacts: lazy(() => import('business/contacts/Contacts')),
  TokenAnalyser: lazy(() => import('business/global/TokenAnalyser')),

  NotFoundPage,
  NoAccessPermissionPage
}

export default Screens
