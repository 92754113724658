import { Route } from 'react-router-dom'
import usePermissions from 'business/shared/utils/Permission/usePermissions'
import useRoles from 'business/shared/utils/Permission/useRoles'
import {
  AdminRoutes,
  AffiliateRoutes,
  ManagerRoutes,
  MasterAffiliateRoutes,
  Route as AppRoute
} from 'config/routes/routes'

export const useAllowedUserRoutes = () => {
  const has = usePermissions()
  const hasRole = useRoles()
  let routes

  if (hasRole('affiliate', 'master_affiliate')) {
    routes = hasRole('master_affiliate')
      ? MasterAffiliateRoutes
      : AffiliateRoutes
  } else {
    routes = hasRole('admin') ? AdminRoutes : ManagerRoutes
  }

  return routes.filter(route => {
    return has(...(route.permission || []))
  })
}

export function makeRoutes(routes: AppRoute[]) {
  return routes.map(({ path, screen: Screen }) => (
    <Route key={path} path={`${path}/*`} element={<Screen />} />
  ))
}
