import { HttpStatus } from '@packages/constants'
import axios from 'axios'
import { ErrorBase, ErrorBaseProps } from 'shared/screens/errors/ErrorBase'
import { UnknownError } from 'shared/screens/errors/UnknownError'

type Props = Pick<ErrorBaseProps, 'fullScreen'> & {
  /**
   * This is intentionally typed in this way to allow for any type of error, and
   * to force the developer to explicitly determine what the error is when handling
   * it.
   */
  error: any | unknown
}

const NetworkErrorStatusCode = 0

const DisplayableResponseErrors: Record<
  number,
  Pick<ErrorBaseProps, 'message' | 'title'>
> = {
  [NetworkErrorStatusCode]: {
    title: 'Network Error',
    message:
      'There was a problem connecting to the server. Please check your internet connection and try again.'
  },
  [HttpStatus.Forbidden]: {
    title: 'Forbidden',
    message: 'You do not have permission to access this page.'
  },
  [HttpStatus.NotFound]: {
    title: 'Not Found',
    message: 'The page or resource you are looking for does not exist.'
  },
  [HttpStatus.PageExpired]: {
    title: 'Page Expired',
    message: 'The page you are looking for has expired.'
  },
  [HttpStatus.TooManyRequests]: {
    title: 'Too Many Requests',
    message: 'You have made too many requests. Please try again later.'
  }
}

/**
 * This component represents a generic error that can be displayed to the user,
 * based on the type of error that was thrown.
 */
export const DisplayableError = ({ error, fullScreen }: Props) => {
  if (axios.isAxiosError(error)) {
    // The response will not be available on the object, if the request was cancelled,
    // or there is a network issue.
    const status = error.response?.status || NetworkErrorStatusCode

    if (status in DisplayableResponseErrors) {
      const { title, message } = DisplayableResponseErrors[status]

      return (
        <ErrorBase title={title} message={message} fullScreen={fullScreen} />
      )
    }
  }

  return <UnknownError fullScreen={fullScreen} />
}
