import { ReactElement } from 'react'
import { TermsAndConditionsAgreementModal } from 'business/shared/terms-and-conditions/TermsAndConditionsAgreementModal'
import useRoles from 'business/shared/utils/Permission/useRoles'
import { useAuth } from 'services/auth.service'
import { useTermsAndConditions } from 'services/platform-configuration.service'

type Props = {
  children: ReactElement
}

export const TermsAndConditionsAgreement = ({ children }: Props) => {
  const auth = useAuth()
  const is = useRoles()

  let hasAgreed = true

  if (is('affiliate', 'master_affiliate')) {
    if (!auth.user?.affiliateProfile) {
      throw new Error('Affiliate Profile not found')
    }

    hasAgreed = Boolean(auth.user.affiliateProfile.agreedToTerms)
  }

  // Using a child component allows us to only render the modal when the
  // terms and conditions are available, while not sending a request to fetch
  // the path to the terms and conditions when the user every time the application is loaded.
  return hasAgreed ? (
    children
  ) : (
    <Agreement hasAgreed={hasAgreed}>{children}</Agreement>
  )
}

const Agreement = ({
  hasAgreed,
  children
}: {
  hasAgreed: boolean
  children: ReactElement
}) => {
  // We don't want to show an error page, when we don't have terms and conditions available.
  // This is why we do not use suspense here.
  const { data: termsAndConditions, error } = useTermsAndConditions()

  return !hasAgreed && !error && termsAndConditions ? (
    <TermsAndConditionsAgreementModal />
  ) : (
    children
  )
}
