import { ModalProps } from '@coddess-development/coddess-ui'
import { AccountModalContent } from 'business/account/affiliate/AccountModalContent'
import { DateTime } from 'luxon'
import { useAuth } from 'services/auth.service'
import { Modal } from 'shared/components/molecules/modal/Modal'

type Props = Omit<ModalProps, 'children'>

export const AccountModal = ({ onClose, ...props }: Props) => {
  const { user } = useAuth()

  if (!user || !user.approved) {
    return null
  }

  const activeSinceDate = DateTime.fromISO(user.approved).toFormat('DDD')

  return (
    <Modal
      {...props}
      onClose={onClose}
      size="xxl"
      title={`Account overview • Active since ${activeSinceDate}`}
    >
      <AccountModalContent onClose={onClose} />
    </Modal>
  )
}
