import { useCountriesOptions } from 'services/countries.service'
import { FormAutocomplete } from 'shared/components/organisms/forms/autocomplete/FormAutocomplete'
import { FormCheckbox } from 'shared/components/organisms/forms/checkbox/FormCheckbox'
import { FormInput } from 'shared/components/organisms/forms/input/FormInput'

export const AccountModalRightPanel = () => {
  const countries = useCountriesOptions()

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-2 w-1/2">
          <FormInput label="Company name" name="affiliateProfile.companyName" />
          <FormInput label="City" name="affiliateProfile.city" />
          <FormInput
            label="Address"
            name="affiliateProfile.address"
            icon="Map"
          />
        </div>
        <div className="flex flex-col gap-2 w-1/2">
          <FormInput
            label="VAT / Tax Number"
            name="affiliateProfile.taxNumber"
          />
          <FormAutocomplete
            label="Country"
            name="affiliateProfile.countryId"
            options={countries.options}
            onScrollNearEnd={countries.loadMore}
            onSearch={countries.search}
            loading={countries.isLoading}
          />
          <FormInput label="ZIP/Postcode" name="affiliateProfile.zipCode" />
        </div>
      </div>
      <FormCheckbox
        name="enableTwoFactorAuth"
        label="2-factor auth (recommended for security reasons)"
      />
    </div>
  )
}
