import { toast } from 'react-toastify'
import { HttpStatus } from '@packages/constants'
import { ObjectValues, ResponseWithMessage } from '@packages/types'
import { AxiosResponse } from 'axios'
import {
  AdminFinancialsPaymentStatuses,
  AffiliateFinancialsPaymentStatuses
} from 'business/financials/shared/const'
import { exportFile } from 'business/shared/helpers/exportFile'
import { useToExportColumns } from 'business/shared/hooks/useToExportColumns'
import { DateTime } from 'luxon'
import { usePaginated, UsePaginatedOptions } from 'services/shared/usePaginated'
import {
  ReportExportRawColumns,
  ReportFileExportFormat
} from 'services/types/Report'
import { DataTableColumns } from 'shared/components/organisms/data-table'
import { download } from 'shared/helpers/downloads/download'
import { SWRFetcherKey, SWRSuspenseConfiguration } from 'shared/types'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'

import {
  FinancialMonthInfo,
  MonthClosureCloseRequestParams,
  Payment,
  PaymentOption,
  PaymentOptionUpdateRequestParams,
  PaymentRejectionParams,
  PaymentRequestCreateRequestParams,
  PaymentStatusSlug
} from './types/Financials'
import client from './client'

const useAffiliateFinancials = (
  statuses: ObjectValues<typeof AffiliateFinancialsPaymentStatuses>
) => {
  const toExportColumns = useToExportColumns()

  const params = { search: '', status: statuses }
  const url = '/affiliate/payment-requests'

  const highlights = useSWR(`/affiliate/payment-monitoring`, { suspense: true })

  const { key, ...result } = usePaginated<Payment, typeof params>(url, {
    params
  })

  const { trigger: requestPayment } = useSWRMutation<
    AxiosResponse<ResponseWithMessage<Payment>>,
    any,
    typeof key,
    PaymentRequestCreateRequestParams
  >(key, async (_, { arg: { file, amount, paymentOptionId } }) => {
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('amount', amount.toString())
    formData.append('paymentOptionId', paymentOptionId.toString())

    const response = (
      await client.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    ).data

    await highlights.mutate()

    toast('Payment Request sent', { type: 'success' })

    return response
  })

  const { trigger: uploadInvoice } = useSWRMutation<
    AxiosResponse<ResponseWithMessage<Payment>>,
    any,
    typeof key,
    { paymentId: number; file: File }
  >(key, async (_, { arg: { paymentId, file } }) => {
    const formData = new FormData()
    formData.append('file', file, file.name)

    const response = await client.put(
      `/affiliate/payment-requests/${paymentId}`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )

    await highlights.mutate()

    if (response.status === HttpStatus.Ok) {
      toast('Invoice updated', { type: 'success' })
    }

    return response
  })

  const { trigger: receivePayment } = useSWRMutation<
    Promise<void>,
    any,
    typeof key,
    number
  >(key, async (_, { arg }) => {
    return client.put(`/affiliate/payment-requests/${arg}/received`)
  })

  const { trigger: exportReport } = useSWRMutation<
    Promise<void>,
    any,
    typeof key,
    ReportExportRawColumns
  >(key, async (_, { arg }) => {
    const { data } = await client.get<Blob>(result.url, {
      params: {
        ...result.filters,
        ...arg,
        total: result.meta.total,
        exportColumns: toExportColumns(arg.exportColumns)
      },
      responseType: arg.exportTo ? 'blob' : undefined
    })

    exportFile('Payment_Requests', arg.exportTo, data)
  })

  return {
    ...result,
    data: result.data,
    highlights: highlights?.data,
    requestPayment: (
      params: PaymentRequestCreateRequestParams,
      options?: Parameters<typeof requestPayment>[1]
    ) => requestPayment(params, options),
    uploadInvoice: (
      paymentId: number,
      file: File,
      options?: Parameters<typeof uploadInvoice>[1]
    ) => uploadInvoice({ paymentId, file }, options),
    export: (
      exportTo: ReportFileExportFormat,
      exportColumns: DataTableColumns<any>,
      options?: Parameters<typeof exportReport>[1]
    ) => exportReport({ exportTo, exportColumns }, options),
    receivePayment: (paymentId: number) => receivePayment(paymentId)
  }
}

export const useAffiliatePaymentMonitoring = () => {
  return useAffiliateFinancials(
    AffiliateFinancialsPaymentStatuses.PaymentMonitoring
  )
}

export const useAffiliateAccounting = () => {
  return useAffiliateFinancials(AffiliateFinancialsPaymentStatuses.Accounting)
}

export function usePaymentRequests<
  U extends boolean = false,
  E extends object = {}
>({
  affiliateId,
  status,
  ...config
}: Omit<UsePaginatedOptions<Payment, any, E>, 'params'> & {
  affiliateId?: number | string
  status?: PaymentStatusSlug[]
}) {
  const params = {
    search: ''
  }

  return usePaginated<Payment, typeof params, U, E>('/payment-requests', {
    params: { ...params, status, affiliateId } as any,
    ...config
  })
}

export const downloadInvoice = async (paymentId: number): Promise<any> => {
  const res = await client.get(
    `/affiliate/payment-requests/${paymentId}/invoice`
  )
  return res.data
}

export function useMonthClosure() {
  const url = '/financials/month-closure'

  const { key, ...result } = usePaginated<FinancialMonthInfo>(url)

  const closeMutation = useSWRMutation<
    AxiosResponse<void>,
    any,
    typeof key,
    MonthClosureCloseRequestParams
  >(key, (_, { arg }) => client.post<void>(url, arg))

  return {
    ...result,
    data: result.data,
    closeFinancialMonth: (
      params: MonthClosureCloseRequestParams,
      options?: Parameters<(typeof closeMutation)['trigger']>[1]
    ) => closeMutation.trigger(params, options)
  }
}

export function usePaymentOptions() {
  const url: SWRFetcherKey = '/financials/payment-options'

  const result = useSWRImmutable<
    PaymentOption[],
    typeof url,
    SWRSuspenseConfiguration
  >(url, { suspense: true, revalidateOnMount: false })

  const update = useSWRMutation<
    PaymentOption,
    any,
    string,
    PaymentOptionUpdateRequestParams & { optionId: string | number }
  >(url, (_, { arg: { optionId, ...params } }) =>
    client.put(`${url}/${optionId}`, params)
  )

  return {
    ...result,
    isUpdating: update.isMutating,
    update: (
      optionId: string | number,
      params: PaymentOptionUpdateRequestParams,
      options?: Parameters<(typeof update)['trigger']>[1]
    ) => update.trigger({ optionId, ...params }, options)
  }
}

export function useFinancialsAffiliateChecklist() {
  return useFinancials(
    AdminFinancialsPaymentStatuses.Checklist,
    'Payment_Requests'
  )
}

export function useFinancialsManagement() {
  return useFinancials(AdminFinancialsPaymentStatuses.Management, 'Management')
}

export function useFinancialsAccounting() {
  return useFinancials(
    AdminFinancialsPaymentStatuses.Accounting,
    'Payment_Requests'
  )
}

export function useFinancials(
  statuses: ObjectValues<typeof AdminFinancialsPaymentStatuses>,
  exportName: string
) {
  const toExportColumns = useToExportColumns()

  const params = { search: '', status: statuses }

  const highlights = useSWR('/payment-monitoring', { suspense: true })

  const { key, ...result } = usePaginated<Payment, typeof params>(
    '/payment-requests',
    { params, revalidateOnMount: true }
  )

  const { trigger: send } = useSWRMutation<
    ResponseWithMessage<void>,
    any,
    typeof key,
    number
  >(key, async (_, { arg }) => {
    const response = await client.put(`/payment-requests/${arg}/send`)

    await highlights.mutate()

    toast(response.data.message, { type: 'success' })

    return response.data
  })

  const { trigger: approve } = useSWRMutation<
    ResponseWithMessage<void>,
    any,
    typeof key,
    number
  >(key, async (_, { arg }) => {
    const response = await client.put(`/payment-requests/${arg}/approve`)

    await highlights.mutate()

    toast(response.data.message, { type: 'success' })

    return response.data
  })

  const { trigger: downloadInvoices } = useSWRMutation(key, async () => {
    const response = await client.get(`/payment-requests/download-invoices`, {
      params: { status: AdminFinancialsPaymentStatuses.Management },
      responseType: 'blob'
    })

    download(response.data, `invoices-${DateTime.now()}.zip`)

    return response.data
  })

  const { trigger: reject } = useSWRMutation<
    ResponseWithMessage<void>,
    any,
    typeof key,
    PaymentRejectionParams
  >(key, async (_, { arg }) => {
    const { id, ...params } = arg
    const response = await client.put(`/payment-requests/${id}/reject`, params)

    await highlights.mutate()

    toast(response.data.message, { type: 'success' })

    return response.data
  })

  const { trigger: exportReport } = useSWRMutation<
    Promise<void>,
    any,
    typeof key,
    ReportExportRawColumns
  >(key, async (_, { arg }) => {
    const { data } = await client.get<Blob>(result.url, {
      params: {
        ...result.filters,
        ...arg,
        total: result.meta.total,
        exportColumns: toExportColumns(arg.exportColumns)
      },
      responseType: arg.exportTo ? 'blob' : undefined
    })

    exportFile(exportName, arg.exportTo, data)
  })

  return {
    ...result,
    data: result.data,
    highlights: highlights.data,
    export: (
      exportTo: ReportFileExportFormat,
      exportColumns: DataTableColumns<any>,
      options?: Parameters<typeof exportReport>[1]
    ) => exportReport({ exportTo, exportColumns }, options),
    send: (id: number, options?: Parameters<typeof send>[1]) =>
      send(id, options),
    approve: (id: number, options?: Parameters<typeof approve>[1]) =>
      approve(id, options),
    reject: (
      id: number,
      params: PaymentRejectionParams,
      options?: Parameters<typeof reject>[1]
    ) => reject({ ...params, id }, options),
    downloadInvoices: () => downloadInvoices()
  }
}
