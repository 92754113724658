import {
  CreditCard,
  FileText,
  Grid,
  Hexagon,
  Icon,
  Settings,
  Share2,
  Sidebar,
  Tool,
  Users,
  Wifi
} from 'react-feather'
import { RoleSlug } from 'services/types/Role'

import { PermissionSlug } from '../../services/types/Permission'
import Screens from '../../shared/screens/Screens'

type Screen = (typeof Screens)[keyof typeof Screens]

export type Route = {
  path: string
  label: string
  screen: Screen
  permission?: PermissionSlug[]
  roles?: RoleSlug[]
  Icon?: Icon
}

export const AffiliateRoutes: Route[] = [
  {
    path: '/dashboard',
    label: 'Dashboard',
    Icon: Grid,
    screen: Screens.AffiliateDashboard,
    permission: ['view-own-dashboard']
  },
  {
    path: '/reports',
    Icon: FileText,
    label: 'Reports',
    screen: Screens.Reports,
    permission: ['view-reports', 'view-own-reports']
  },
  {
    path: '/marketing',
    Icon: Share2,
    label: 'Marketing',
    screen: Screens.Marketing,
    permission: ['view-marketing', 'view-own-marketing']
  },
  {
    path: '/tools',
    Icon: Tool,
    label: 'Tools',
    screen: Screens.Tools,
    permission: ['view-tools', 'view-own-tools']
  },
  {
    path: '/financials',
    Icon: CreditCard,
    label: 'Financials',
    screen: Screens.Financials,
    permission: ['view-financials', 'view-own-payment-requests']
  },
  {
    path: '/contacts',
    label: 'Contacts',
    Icon: Users,
    screen: Screens.Contacts,
    permission: ['view-own-contacts']
  }
]

export const MasterAffiliateRoutes: Route[] = [
  ...AffiliateRoutes,
  {
    path: '/master',
    Icon: Wifi,
    label: 'Master Affiliate',
    screen: Screens.MasterAffiliateDashboard,
    permission: ['view-own-master-affiliate-dashboard']
  }
]

export const AdminRoutes: Route[] = [
  {
    path: '/management',
    label: 'Management',
    Icon: Sidebar,
    screen: Screens.Management,
    permission: ['view-management-section']
  },
  {
    path: '/affiliates',
    Icon: Users,
    label: 'Affiliates',
    screen: Screens.Affiliates,
    permission: ['view-affiliate-list']
  },
  {
    path: '/reports',
    Icon: FileText,
    label: 'Reports',
    screen: Screens.Reports,
    permission: ['view-reports', 'view-own-reports']
  },
  {
    path: '/marketing',
    Icon: Share2,
    label: 'Marketing',
    screen: Screens.Marketing,
    permission: ['view-marketing', 'view-own-marketing']
  },
  {
    path: '/configuration',
    Icon: Settings,
    label: 'Configuration',
    screen: Screens.Configuration,
    permission: ['view-configuration']
  },
  {
    path: '/tools',
    Icon: Tool,
    label: 'Tools',
    screen: Screens.Tools,
    permission: ['view-tools', 'view-own-tools']
  },
  {
    path: '/financials',
    Icon: CreditCard,
    label: 'Financials',
    screen: Screens.Financials,
    permission: ['view-financials', 'view-own-payment-requests']
  },
  {
    path: '/newton',
    Icon: Hexagon,
    label: 'Newton',
    screen: Screens.Newton,
    permission: ['view-newton']
  },
  {
    path: '/account/:id',
    label: 'Account',
    screen: Screens.AccountOverview,
    permission: ['view-affiliate-list']
  }
]

export const ManagerRoutes: Route[] = [...AdminRoutes]

export const PublicRoutes: Route[] = [
  {
    path: '/tracking-links/analyse-token',
    label: 'Token Analyser',
    screen: Screens.TokenAnalyser
  }
]
