import { Puff } from 'react-loader-spinner'

export type Props = {
  height?: 'full' | 'screen' | 'auto'
}

const LoadingScreen = ({ height = 'screen' }: Props) => {
  return (
    <div
      className={`flex flex-col items-center justify-center w-full h-${height}`}
    >
      <Puff color="#2B77FF" height={50} width={50} key="loading" />
      <p data-testid="loading-screen-text">Loading..</p>
    </div>
  )
}

export default LoadingScreen
