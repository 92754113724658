import { startTransition, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import client from 'services/client'
import { getQueryString } from 'shared/helpers/getQueryString'
import { Option, SWRFetcherKey } from 'shared/types'
import useSWRImmutable from 'swr/immutable'
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader
} from 'swr/infinite'

export type InfiniteOptionsOptionValueType = string | number

export type InfiniteOptionsInitialValue =
  | InfiniteOptionsOptionValueType
  | InfiniteOptionsOptionValueType[]
  | null

export type InfiniteOptionsConfig<
  T,
  I extends InfiniteOptionsInitialValue = null,
  O extends InfiniteOptionsOptionValueType | null = null,
  P extends object = any
> = SWRInfiniteConfiguration<T> & {
  /**
   * This represents the value or label of the initial option that is selected. It should be used
   * to make sure that this option is initially loaded and available for presentation to the user.
   */
  initial?: I
  params?: P
  toOption?: (item: T) => Option<O>
}

const perPage = 15

/**
 * Common abstraction 'infinite' option list resources.
 */
export function useInfiniteOptions<
  T,
  I extends InfiniteOptionsInitialValue = string,
  O extends InfiniteOptionsOptionValueType | null = null,
  P extends object = any
>(
  url: string,
  {
    params,
    toOption,
    initial = '' as I,
    ...config
  }: InfiniteOptionsConfig<T, I, O, P> = {}
) {
  const isMounted = useRef(true)

  useEffect(() => () => {
    isMounted.current = false
  })

  const [search, setSearch] = useState('')
  const [preload, setPreload] = useState<I | null>(initial)

  const preloadKey: SWRFetcherKey | null = preload
    ? [url, { preload: Array.isArray(preload) ? preload : [preload] }]
    : null

  const preloadResponse = useSWRImmutable(preloadKey, {
    suspense: true,
    revalidateOnMount: false,
    onSuccess: () => isMounted.current && setPreload(null)
  })

  const preloadedData = (preloadResponse.data?.data?.flat() || []) as T[]

  const loader: SWRInfiniteKeyLoader<T[]> = (index, previousPageData) => {
    if (previousPageData && !previousPageData?.length) {
      return null
    }

    const nextPage = index + 1
    const query = getQueryString({
      ...params,
      perPage,
      search,
      page: nextPage
    })

    return `${url}${query}`
  }

  const { size, setSize, ...response } = useSWRInfinite<T>(
    loader,
    url => client.get(url).then(r => r.data.data),
    {
      revalidateFirstPage: false,
      revalidateOnMount: true,
      // We use suspense only if we need to preload data, which we actually do above.
      suspense: false,
      ...config
    }
  )

  const data = uniqBy(
    [...((response.data?.flat() || []) as T[]), ...preloadedData],
    'id'
  )

  const isLoadingMore =
    response.isLoading ||
    (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.length === 0
  const isReachingEnd = isEmpty || !Number.isInteger(data.length / perPage)

  return {
    ...response,
    data,
    isLoadingMore,
    isReachingEnd,
    toOption: toOption as O extends null ? never : (item: T) => Option<O>,
    searchValue: search,
    // We set the debounce duration to 650ms to avoid sending multiple requests for the same search
    search: useMemo(
      () =>
        debounce(
          search =>
            startTransition(() => {
              setSearch(search)
            }),
          650
        ),
      []
    ),
    loadMore: () => {
      if (isLoadingMore || isReachingEnd) {
        return
      }

      setSize(size + 1)
    },
    get options() {
      if (!toOption) {
        throw new Error('toOption is required to use options')
      }

      return data.map(v => toOption(v))
    },
    get initialOption(): I extends InfiniteOptionsOptionValueType
      ? Option<O> | null
      : I extends InfiniteOptionsOptionValueType[]
        ? Option<O>[] | null
        : null {
      if (!initial) {
        return null as any
      }

      if (Array.isArray(initial)) {
        return this.options.filter(opt =>
          initial.some(
            val =>
              opt.label.includes(String(val)) ||
              String(opt.value) === String(val)
          )
        ) as any
      }

      return (
        (this.options.find(
          opt =>
            opt.label.includes(String(initial)) ||
            String(opt.value) === String(initial)
        ) as any) || null
      )
    }
  }
}
