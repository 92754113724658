import { useParams } from 'react-router'
import { useMediaImpression } from 'services/media.service'

export const PublicMediaImpression = () => {
  const media = useMediaImpression(useParams().token!, useParams().affiliate!)

  return (
    <div className="h-screen w-full truncate">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={media.data.href}
        className="w-full h-full"
      >
        <img width="100%" height="100%" src={media.data.src} alt="Media" />
      </a>
    </div>
  )
}
