/**
 * Based on https://stackoverflow.com/a/53230807.
 */
export function download(
  data: Parameters<(typeof URL)['createObjectURL']>[0] | string,
  name: string
) {
  const href = typeof data === 'string' ? data : URL.createObjectURL(data)

  const link = document.createElement('a')

  link.href = href
  link.download = name
  link.target = '_blank'

  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
