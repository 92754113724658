import { UIEvent } from 'react'

export function createInfiniteScrollHandler<T extends HTMLElement>(
  cb: () => void
) {
  return (e: UIEvent<T>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop < 220) {
      cb()
    }
  }
}
