export type CurrencyFormatOptions = Pick<Intl.NumberFormatOptions, 'currency'> &
  Partial<{
    maxDecimals?: number
  }>

export const formatCurrency = (
  val: string | number = 0,
  { maxDecimals = 2, currency = 'EUR' }: CurrencyFormatOptions = {}
) => {
  if (typeof val === 'string') {
    val = parseFloat(val)
  }

  return `${new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    maximumFractionDigits: maxDecimals
  }).format(val)}`
}
