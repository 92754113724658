import { AccountModalLeftPanel } from 'business/account/affiliate/AccountModalLeftPanel'
import { AccountModalRightPanel } from 'business/account/affiliate/AccountModalRightPanel'

export const AccountModalFormFields = () => {
  return (
    <div className="flex flex-row gap-4">
      <div className="w-1/2">
        <AccountModalLeftPanel />
      </div>
      <div className="w-1/2">
        <AccountModalRightPanel />
      </div>
    </div>
  )
}
