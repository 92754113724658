"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelName = exports.AffiliateStatus = exports.TwoFactorAuthenticationMethod = void 0;
exports.TwoFactorAuthenticationMethod = {
    Email: 'email'
};
exports.AffiliateStatus = {
    Approved: 'approved',
    Rejected: 'rejected',
    Waiting: 'waiting',
    Suspended: 'suspended'
};
exports.LabelName = {
    New: 'new',
    Active: 'active',
    Dormant: 'dormant'
};
