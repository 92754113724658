"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageType = exports.Product = void 0;
var Product;
(function (Product) {
    Product["Sport"] = "sport";
    Product["Casino"] = "casino";
    Product["Other"] = "other";
})(Product || (exports.Product = Product = {}));
var LandingPageType;
(function (LandingPageType) {
    LandingPageType["A"] = "A";
    LandingPageType["B"] = "B";
})(LandingPageType || (exports.LandingPageType = LandingPageType = {}));
