import { useEffect } from 'react'
import { toast, TypeOptions } from 'react-toastify'
import { HttpStatus } from '@packages/constants'
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { baseURL, config } from 'config/constants'
import { useAuth } from 'services/auth.service'
import { UnprocessableEntityResponse } from 'services/types/General'
import { getQueryString } from 'shared/helpers/getQueryString'

const client = axios.create()

client.interceptors.request.use((conf: AxiosRequestConfig) => {
  const headers = { ...conf.headers, ...config().headers }

  // This is necessary, as currently axios appends files as empty objects
  // when using 'multipart/form-data'. This behavior might be changed in the future.
  // Temporary solution: https://github.com/axios/axios/issues/5986
  const transformRequest =
    conf.headers?.['Content-Type'] === 'multipart/form-data'
      ? [(data: any) => data]
      : conf.transformRequest

  return {
    paramsSerializer: {
      serialize: params =>
        getQueryString(params, { addQueryPrefix: false, encode: true })
    },
    ...conf,
    baseURL,
    ...config(),
    transformRequest,
    headers: headers as unknown as AxiosRequestHeaders
  }
})

client.interceptors.response.use(
  response => response,
  error => {
    let type: TypeOptions = 'error'
    let message = 'Something went wrong'

    if (axios.isAxiosError(error)) {
      const { response } = error
      if (!response) {
        message = 'Network unavailable'
      } else {
        switch (response.status) {
          case HttpStatus.BadRequest:
            message =
              'Error: Bad request. Please report this issue, if it persists.'
            break

          case HttpStatus.Unauthorized: {
            const responseUrl = response.config.url ?? ''
            // We want to show an error message on unsuccessful login or token verification.
            // This functionality is further extended within the React flow to redirect the user
            // to the login page, when their credentials are invalid or stale.
            switch (true) {
              case responseUrl.endsWith('/login'):
                message = response.data.error || 'Error: Wrong credentials'
                break
              case responseUrl.endsWith('/verify-token'):
                message = response.data.error || 'Error: Invalid token'
                break
            }
            break
          }

          case HttpStatus.Forbidden:
            if (response.data.user && !response.data.user.approved) {
              type = 'warning'
              message = 'Your account application is still under review'
            } else {
              // Automatically redirect users to root when a request hits a Forbidden
              // resource. This is normally an indicator that we either have a bug, or
              // the user and/or resource we've stored in cache/memory are stale.
              // A redirect to root will normally either lead to the Dashboard for the user or login.
              document.location = '/'
              message = 'Error: Forbidden'
            }
            break

          case HttpStatus.NotFound:
            message = 'Error: Not Found'
            break

          case HttpStatus.Conflict:
            message = response.data.error || error.message
            break

          case HttpStatus.UnprocessableEntity: {
            const { errors } = response.data as UnprocessableEntityResponse
            const firstError = errors[0]

            if (firstError) {
              message = firstError.message
            }

            break
          }

          case HttpStatus.TooManyRequests:
            // There's basically nothing we can do. The user has reached
            // a rate limit and has to just chill a little.
            message = 'Too Many Requests. Try again in a moment.'
            break

          case HttpStatus.InternalServerError:
            message = 'Internal Server Error!'
            break
        }
      }
    }

    if (message) {
      toast(message, {
        type,
        closeButton: true,
        closeOnClick: true
      })
    }

    throw error
  }
)

/**
 * Setup the Axios client with React runtime dependant configurations.
 * This should ideally only be called once within the App component.
 */
export function useSetupAxiosClient() {
  const { logout } = useAuth()

  useEffect(() => {
    const interceptor = client.interceptors.response.use(undefined, error => {
      if (!axios.isAxiosError(error)) {
        return
      }

      const { response } = error

      if (!response) {
        return
      }

      switch (response.status) {
        case HttpStatus.Unauthorized: {
          const responseUrl = response.config.url ?? ''
          if (!responseUrl.endsWith('/verify-token')) {
            logout()
          }

          break
        }
        case HttpStatus.PageExpired: {
          // We do not have a mechanism to refresh the auth token, while at the
          // same time we do not rely on cookies, which might expired. Therefore,
          // the only thing that we can do to assist the user is to force them to log back in.
          logout()
          break
        }
      }

      throw error
    })

    return () => {
      client.interceptors.response.eject(interceptor)
    }
  })
}

export default client
