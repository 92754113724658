import { ComponentType } from 'react'
import { useLocation } from 'react-router-dom'

export type RouteComponentProps = {
  router: {
    location: ReturnType<typeof useLocation>
  }
}

export function withRouter<P extends object = any>(
  Component: ComponentType<P & RouteComponentProps>
) {
  // We want component to be named for React Devtools, but we can't use the `displayName` property here.
  // eslint-disable-next-line react/function-component-definition
  return function WithRouter(props: Omit<P, keyof RouteComponentProps>) {
    return (
      <Component
        {...(props as P)}
        router={{
          location: useLocation()
        }}
      />
    )
  }
}
