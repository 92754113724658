import { OTP_CODE_LENGTH } from '@packages/constants'
import { TwoFactorAuthenticationNumberInput } from 'business/shared/2fa/TwoFactorAuthenticationNumberInput'

export const TwoFactorAuthenticationCodeContainerId = '2fa-code-container'

export const TwoFactorAuthenticationCodeInput = () => {
  return (
    <div className="flex flex-col align-center gap-2">
      <p>{OTP_CODE_LENGTH}-digit code</p>
      <div
        id={TwoFactorAuthenticationCodeContainerId}
        className="flex flex-row gap-1"
      >
        {Array.from({ length: OTP_CODE_LENGTH }, (_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TwoFactorAuthenticationNumberInput index={index} key={index} />
        ))}
      </div>
    </div>
  )
}
