"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpStatus = void 0;
/**
 * HTTP status code constants as defined per the documentation at
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Status.
 */
var HttpStatus;
(function (HttpStatus) {
    /**
     * The request succeeded. The result meaning of "success" depends on the HTTP method:
     * - `GET`: The resource has been fetched and transmitted in the message body.
     * - `HEAD`: The representation headers are included in the response without any message body.
     * - `PUT` or `POST`: The resource describing the result of the action is transmitted in the message body.
     * - `TRACE`: The message body contains the request message as received by the server.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/200
     */
    HttpStatus[HttpStatus["Ok"] = 200] = "Ok";
    /**
     * The request succeeded, and a new resource was created as a result.
     * This is typically the response sent after `POST` requests, or some `PUT` requests.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/201
     */
    HttpStatus[HttpStatus["Created"] = 201] = "Created";
    /**
     * The request has been received but not yet acted upon. It is noncommittal,
     * since there is no way in HTTP to later send an asynchronous response indicating the outcome of the request.
     * It is intended for cases where another process or server handles the request, or for batch processing.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/202
     */
    HttpStatus[HttpStatus["Accepted"] = 202] = "Accepted";
    /**
     * There is no content to send for this request, but the headers may be useful.
     * The user agent may update its cached headers for this resource with the new ones.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/204
     */
    HttpStatus[HttpStatus["NoContent"] = 204] = "NoContent";
    /**
     * The URL of the requested resource has been changed permanently.
     * The new URL is given in the response.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/301
     */
    HttpStatus[HttpStatus["MovedPermanently"] = 301] = "MovedPermanently";
    /**
     * This response code means that the URI of requested resource has been changed temporarily.
     * Further changes in the URI might be made in the future. Therefore, this same URI should be used by the client in future requests.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/302
     */
    HttpStatus[HttpStatus["Found"] = 302] = "Found";
    /**
     * The server sent this response to direct the client to get the requested resource at another URI with a `GET` request.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/303
     */
    HttpStatus[HttpStatus["SeeOther"] = 303] = "SeeOther";
    /**
     * This is used for caching purposes. It tells the client that the response has not been modified,
     * so the client can continue to use the same cached version of the response.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/304
     */
    HttpStatus[HttpStatus["NotModified"] = 304] = "NotModified";
    /**
     * The server sent this response to direct the client to get the requested resource
     * at another URI with same method that was used in the prior request. This has the same semantics as the
     * `302 Found` HTTP response code, with the exception that the user agent must not change the HTTP method used:
     * If a `POST` was used in the first request, a `POST` must be used in the second request.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/307
     */
    HttpStatus[HttpStatus["TemporaryRedirect"] = 307] = "TemporaryRedirect";
    /**
     * This means that the resource is now permanently located at another URI,
     * specified by the `Location: HTTP Response` header.
     * This has the same semantics as the `301 Moved Permanently` HTTP response code,
     * with the exception that the user agent must not change the HTTP method used:
     * If a `POST` was used in the first request, a `POST` must be used in the second request.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/308
     */
    HttpStatus[HttpStatus["PermanentRedirect"] = 308] = "PermanentRedirect";
    /**
     * The server could not understand the request due to invalid syntax.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/400
     */
    HttpStatus[HttpStatus["BadRequest"] = 400] = "BadRequest";
    /**
     * Although the HTTP standard specifies "unauthorized", semantically this response means "unauthenticated".
     * That is, the client must authenticate itself to get the requested response.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
     */
    HttpStatus[HttpStatus["Unauthorized"] = 401] = "Unauthorized";
    /**
     * The client does not have access rights to the content, i.e. they are unauthorized,
     * so server is rejecting to give proper response. Unlike `401`, the client's identity is known to the server.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
     */
    HttpStatus[HttpStatus["Forbidden"] = 403] = "Forbidden";
    /**
     * The server can not find requested resource. In the browser, this means the URL is not recognized.
     * In an API, this can also mean that the endpoint is valid but the resource itself does not exist.
     * Servers may also send this response instead of `403` to hide the existence of a resource from an unauthorized client.
     * This response code is probably the most famous one due to its frequent occurrence on the web.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/404
     */
    HttpStatus[HttpStatus["NotFound"] = 404] = "NotFound";
    /**
     * This response is sent on an idle connection by some servers, even without any previous request by the client.
     * It means that the server would like to shut down this unused connection. This response is used much more since some browsers,
     * like Chrome, Firefox 27+, or IE9, use HTTP pre-connection mechanisms to speed up surfing. Also note that some servers merely
     * shut down the connection without sending this message.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/408
     */
    HttpStatus[HttpStatus["Timeout"] = 408] = "Timeout";
    /**
     * This response is sent when a request conflicts with the current state of the server.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/409
     */
    HttpStatus[HttpStatus["Conflict"] = 409] = "Conflict";
    HttpStatus[HttpStatus["PageExpired"] = 419] = "PageExpired";
    /**
     * The request was well-formed but was unable to be followed due to semantic errors.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/422
     */
    HttpStatus[HttpStatus["UnprocessableEntity"] = 422] = "UnprocessableEntity";
    /**
     * The user has sent too many requests in a given amount of time ("rate limiting").
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/429
     */
    HttpStatus[HttpStatus["TooManyRequests"] = 429] = "TooManyRequests";
    /**
     * The server has encountered a situation it does not know how to handle.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/500
     */
    HttpStatus[HttpStatus["InternalServerError"] = 500] = "InternalServerError";
    /**
     * The server is not ready to handle the request.
     * Common causes are a server that is down for maintenance or that is overloaded.
     * Note that together with this response, a user-friendly page explaining the problem should be sent.
     * This response should be used for temporary conditions and the Retry-After HTTP header should,
     * if possible, contain the estimated time before the recovery of the service.
     * The webmaster must also take care about the caching-related headers that are sent along with this response,
     * as these temporary condition responses should usually not be cached.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/503
     */
    HttpStatus[HttpStatus["ServiceUnavailable"] = 503] = "ServiceUnavailable";
})(HttpStatus || (exports.HttpStatus = HttpStatus = {}));
