import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2b77ff'
    },
    background: {
      default: '#23242a' // grey-800
    },
    warning: {
      main: '#fabb2b',
      light: '#ffd981'
    },
    error: {
      main: '#ea4451'
    },
    success: {
      main: '#00c557'
    },
    white: {
      main: '#ffffff'
    },
    grey: {
      '900': '#161419',
      '800': '#23242a',
      '700': '#3f3f45',
      '600': '#52525a',
      '500': '#717179',
      '400': '#a1a1a9',
      '300': '#d4d4d8',
      '200': '#e4e4e7',
      '100': '#f4f4f5',
      '50': '#fafafa'
    },
    blue: {
      '900': '#0d47a1',
      '800': '#1565c0',
      '700': '#1976d2',
      '600': '#1e88e5',
      '500': '#2196f3',
      '400': '#42a5f5',
      '300': '#64b5f6',
      '200': '#90caf9',
      '100': '#bbdefb',
      '50': '#e3f2fd'
    },
    red: {
      '900': '#d32f2f',
      '800': '#e53935',
      '700': '#f44336',
      '600': '#ef5350',
      '500': '#ff5252',
      '400': '#e57373',
      '300': '#ff8a80',
      '200': '#ef9a9a',
      '100': '#ffcdd2',
      '50': '#ffebee'
    }
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontSize: 16
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          border: `1px solid ${theme.palette.grey[600]}`,
          backgroundColor: theme.palette.background.default,
          fontSize: theme.typography.caption.fontSize,
          padding: theme.spacing(2)
        })
      },
      defaultProps: {
        enterDelay: 1500
      }
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: '10000 !important'
        }
      }
    },
    MuiBadge: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        badge: ({ theme }) => ({
          right: theme.spacing(3)
        })
      }
    }
  }
})
