import { Suspense, SuspenseProps } from 'react'
import { ErrorBaseProps } from 'shared/screens/errors/ErrorBase'
import { ErrorBoundary } from 'shared/screens/errors/ErrorBoundary'
import LoadingScreen from 'shared/screens/LoadingScreen'

type Props = {
  children: React.ReactNode
} & Partial<
  Pick<ErrorBaseProps, 'fullScreen'> & Pick<SuspenseProps, 'fallback'>
>

/**
 * A universal component that wraps a Suspense boundary with an ErrorBoundary.
 *
 * This is useful for when you want to show a loading screen while a component
 * is being loaded, but also want to show an error screen if the component
 * fails to load.
 *
 * You can call it... Our loading Swiss Army knife!
 */
export const SuspenseBoundary = ({ fullScreen, fallback, children }: Props) => {
  return (
    <ErrorBoundary fullScreen={fullScreen}>
      <Suspense
        fallback={
          fallback || (
            <div className="w-full py-16">
              <LoadingScreen height="full" />
            </div>
          )
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}
