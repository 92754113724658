import { FormInput } from 'shared/components/organisms/forms/input/FormInput'

export const AccountModalLeftPanel = () => {
  return (
    <div className="flex flex-col gap-2">
      <FormInput label="Name" name="name" />
      <FormInput
        label="Phone number"
        name="userProfile.phoneNumber"
        type="tel"
        icon="Phone"
      />
      <FormInput
        label="New Password"
        name="password"
        type="password"
        icon="Lock"
      />
      <FormInput label="Email" name="email" type="email" icon="Mail" />
      <FormInput label="Skype/AIM" name="userProfile.skype" />
      <FormInput label="Website" name="affiliateProfile.website" type="url" />
    </div>
  )
}
