import { ReactNode } from 'react'
import { classNames } from 'shared/helpers/classnames/classNames'
import { useDocumentTitle } from 'shared/hooks/document-title/useDocumentTitle'

export type ErrorBaseProps = {
  children?: ReactNode
  message?: string
  title: string
  /**
   * Whether element should attempt to fill the entire screen, or at least the
   * entire parent element's available space.
   *
   * @default false
   */
  fullScreen?: boolean
}

/**
 * Base component for all error pages.
 */
export const ErrorBase = ({
  children,
  message,
  title,
  fullScreen
}: ErrorBaseProps) => {
  useDocumentTitle(title)

  return (
    <div
      className={classNames('flex-center flex-col gap-2 px-4 py-16', {
        'w-screen h-screen': !!fullScreen,
        'w-full h-full': !fullScreen
      })}
    >
      <p className="text-xl color-white">{title}</p>
      {message && <p>{message}</p>}
      <div className="flex-center gap-2 p-4">{children}</div>
    </div>
  )
}
