import {
  Modal as CoddessModal,
  ModalProps
} from '@coddess-development/coddess-ui'
import { SuspenseBoundary } from 'shared/screens/SuspenseBoundary'

export const Modal = ({ children, open, ...props }: ModalProps) => {
  return (
    <CoddessModal open={open} {...props}>
      <SuspenseBoundary>{children}</SuspenseBoundary>
    </CoddessModal>
  )
}
