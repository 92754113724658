import { TwoFactorAuthentication } from 'business/shared/2fa/TwoFactorAuthentication'
import { AuthenticatedRoutes } from 'config/routes/AuthenticatedRoutes'
import { GuestRoutes } from 'config/routes/GuestRoutes'
import { useAuth } from 'services/auth.service'
import { useSetupAxiosClient } from 'services/client'
import { SuspenseBoundary } from 'shared/screens/SuspenseBoundary'

export const App = () => {
  useSetupAxiosClient()

  const auth = useAuth()
  const { isLoggedIn } = auth

  return (
    <SuspenseBoundary>
      <TwoFactorAuthentication>
        {isLoggedIn ? <AuthenticatedRoutes /> : <GuestRoutes />}
      </TwoFactorAuthentication>
    </SuspenseBoundary>
  )
}
