import { useCallback, useMemo } from 'react'
import { NavigateOptions, useSearchParams } from 'react-router-dom'
import * as qs from 'qs'
import {
  getQueryString,
  QueryStringParams
} from 'shared/helpers/getQueryString'

export type SearchQueryParams<T extends QueryStringParams> = {
  [key in keyof T]: T[key] extends any[]
    ? T[key] extends string[]
      ? T[key] | undefined
      : string[] | undefined
    : T[key] extends string
      ? T[key] | undefined
      : string | undefined
}

/**
 * Url query string manipulation tools.
 *
 * This is a wrapper of `React Router`'s `useSearchParams`, integrated with `qs` for ease of use and consistency.
 *
 * @param initial Initial query params.
 * @param key A unique key to attach to each parameter name.
 */
export function useSearchQuery<T extends QueryStringParams>(
  initial: T = {} as T,
  key = ''
) {
  const [searchParams, setSearchParams] = useSearchParams(
    toSearchParamsQueryString(withKey(key, initial))
  )

  /**
   * We're memoizing the identity of this function, in order to provide developer experience similar to `useState`'s `setState`.
   * This allows us to then provide this function as a parameter to other react hook memoization dependencies,
   * such as `useMemo` when we want to debounce settings url query parameters.
   */
  const setParams = useCallback(
    (
      nextParams: Partial<Record<keyof T, any>> = {},
      opts: NavigateOptions = {}
    ) =>
      setSearchParams(
        prev =>
          toSearchParamsQueryString({
            ...parseSearchQueryParams(prev),
            ...withKey(key, nextParams)
          }),
        opts
      ),
    [key, setSearchParams]
  )

  const params = useMemo(
    () =>
      withoutKey(
        key,
        parseSearchQueryParams(searchParams)
      ) as SearchQueryParams<T>,
    [key, searchParams]
  )

  return [params, setParams] as const
}

export function toSearchParamsQueryString(
  params: Parameters<typeof getQueryString>[0]
) {
  return getQueryString(params, { addQueryPrefix: false })
}

export function parseSearchQueryParams(search: string | URLSearchParams) {
  return qs.parse(search.toString())
}

function withKey<T extends QueryStringParams>(key: string, params: T): T {
  if (!key) {
    return params
  }

  return Object.entries(params).reduce(
    (acc, [originalKey, value]) => ({
      ...acc,
      [`${formatKey(key)}${originalKey}`]: value
    }),
    {}
  ) as T
}

function withoutKey<T extends QueryStringParams>(key: string, params: T): T {
  return Object.entries(params).reduce((acc, [originalKey, value]) => {
    // Only include values starting with the key when one is present.
    if (key && originalKey.startsWith(formatKey(key))) {
      return { ...acc, [originalKey.replace(formatKey(key), '')]: value }
    }

    // Otherwise exclude values with any key.
    if (!key && originalKey.includes(formatKey(''))) {
      return acc
    }

    // And return the values with their original keys.
    return { ...acc, [originalKey]: value }
  }, {}) as T
}

function formatKey(key: string) {
  return `${key}__`
}
