import { Menu } from 'react-feather'
import { IconButton } from '@coddess-development/coddess-ui'
import { AppBar } from '@mui/material'
import usePermissions from 'business/shared/utils/Permission/usePermissions'
import { IconSizes } from 'shared/components/atoms/buttons/const'
import { MobileSearch } from 'shared/components/organisms/mobile-navbar/MobileSearch'

type Props = {
  isDrawerOpen: boolean
  toggleDrawer: () => void
}

const MobileNavbar = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const has = usePermissions()

  return (
    <AppBar position="sticky" className="mobile-navbar">
      <IconButton
        icon={<Menu size={IconSizes.lg} />}
        size="lg"
        onClick={toggleDrawer}
        className={`drawer-button ${isDrawerOpen ? 'active' : ''}`}
      />
      {has('view-affiliate-list') ? (
        <div className="w-full flex-end">
          <MobileSearch />
        </div>
      ) : null}
    </AppBar>
  )
}

export default MobileNavbar
