import { InputText, InputTextProps } from '@coddess-development/coddess-ui'
import { useField } from 'formik'

type OwnProps = {}

export type FormInputProps = OwnProps &
  Omit<InputTextProps, 'invalid' | 'value' | 'ref' | keyof OwnProps>

export const FormInput = ({
  name,
  onChange,
  className,
  ...rest
}: FormInputProps) => {
  const [{ value }, { error, touched }, { setTouched, setValue }] = useField({
    name
  })

  return (
    <div className={className}>
      <InputText
        {...rest}
        name={name}
        onChange={(text, e) => {
          // Formik is meant to be used with onChange and be passed a valid InputEvent
          // to automatically update the touched state otherwise. Thus we have to update
          // the touch state manually in order to display the error below.
          setTouched(true)

          setValue(text)
          onChange?.(text, e)
        }}
        invalid={touched && !!error}
        value={value}
      />
      <div className="error">{touched && error}</div>
    </div>
  )
}
