import { Component, PropsWithChildren } from 'react'
import { useLocation } from 'react-router'
import {
  RouteComponentProps,
  withRouter
} from 'shared/helpers/react-router-utils'
import { DisplayableError } from 'shared/screens/errors/DisplayableError'
import { ErrorBaseProps } from 'shared/screens/errors/ErrorBase'

type Props = PropsWithChildren<RouteComponentProps> &
  Pick<ErrorBaseProps, 'fullScreen'>

type State = {
  error?: any
  location: ReturnType<typeof useLocation>
}

/**
 * This boundary is meant to catch common navigation and request errors and
 * provide an informative fallback, instead of letting the application crash.
 * Meant to be used anywhere considered necessary.
 */
export const ErrorBoundary = withRouter(
  class ErrorBoundary extends Component<Props, State> {
    // eslint-disable-next-line react/state-in-constructor
    state: State = {
      // eslint-disable-next-line react/destructuring-assignment
      location: this.props.router.location
    }

    static getDerivedStateFromProps(props: Props, state: State): State {
      // There are some cases where an error bubbles up to an ErrorBoundary
      // that is above the main group of Routes. The appropriate error is
      // displayed, but navigatton is not able to clear this error, due to the
      // fact that no Routes are rendered to reflect the location change.
      //
      // One way to deal with this is to add `key` prop to the ErrorBoundary with
      // the current location. This, however, forces every single component
      // instance below to be destroyed and re-created, regardless of the fact
      // that some components may have already been rendered there. This leads
      // to all kinds of unwanted side effects, but mainly it interrupts
      // animations and leads to some jaggged and laggy navigations.
      //
      // So a better approach is to intercept the location change within gDSFP
      // and clear error state whenever a navigation is made. This is achieved
      // by injecting the Router props into the ErrorBoundary. As a result the
      // ErrorBoundary will rerender every time when the location changes, but
      // only the components that need to render below will render.
      const currentLocation = props.router.location
      const prevLocation = state.location

      return {
        error: currentLocation === prevLocation ? state.error : null,
        location: currentLocation
      }
    }

    static getDerivedStateFromError(error: any) {
      return { error }
    }

    render() {
      const { error } = this.state
      const { children, fullScreen } = this.props

      return error ? (
        <DisplayableError error={error} fullScreen={fullScreen} />
      ) : (
        children
      )
    }
  }
)
