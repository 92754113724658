import { ComponentProps, ReactNode } from 'react'
import { HttpStatus } from '@packages/constants'
import axios from 'axios'
import { Duration } from 'luxon'
import client from 'services/client'
import { SWRFetcherKey } from 'shared/types'
import { SWRConfig, SWRConfiguration } from 'swr'

type Props = {
  children: ReactNode
  config?: ComponentProps<typeof SWRConfig>['value']
}

const SWRConfigValue: SWRConfiguration = {
  fetcher: async (key: SWRFetcherKey) => {
    const url = typeof key === 'string' ? key : key[0]
    const params = typeof key === 'string' ? undefined : key[1]

    const response = await client.get(url, { params })

    return response.data
  },
  revalidateIfStale: true,
  // Currently, we don't want to have excessive number of requests in production, but
  // revalidating on focus is useful in development environment, where we frequently make changes.
  revalidateOnFocus: process.env.NODE_ENV === 'development',
  revalidateOnReconnect: true,
  keepPreviousData: true,
  suspense: false,
  dedupingInterval: Duration.fromObject({
    seconds: process.env.NODE_ENV === 'production' ? 5 : 1
  }).toMillis(),
  shouldRetryOnError: (error: unknown) => {
    if (axios.isAxiosError(error) && error.response) {
      const code = error.response.status

      // We do not want to retry on client side errors, except for when there
      // are too many retries, which is partially due to server side limitations.
      // On the other hand, errors >500 are considered recoverable, because they
      // are purely server related and are sometimes transient, which means
      // that a retry could possibly restore the application flow correctly.
      if (
        code >= 400 &&
        code !== HttpStatus.UnprocessableEntity &&
        code <= 499
      ) {
        return false
      }
    }

    return true
  }
}

/**
 * This is the standard deduping interval that should be used for 'static' data, meaning
 * data that is not expected to be frequently or even ever updated on the server side.
 *
 * Prefer using `swr/immutable` where possible instead.
 */
export const StaticDedupingInterval = Duration.fromObject({
  hours: 6
}).toMillis()

/**
 * This is the standard deduping interval that should be used for resources which are
 * expected to be updated occassinally, but it would not be a problem, if they are
 * sometimes stale.
 */
export const LongDedupingInterval = Duration.fromObject({
  minutes: 25
}).toMillis()

/**
 * This is the standard deduping interval that should be used for resources which are
 * expected to be updated frequently, but would not be a problem, if they are
 * stale for a while.
 */
export const MediumDedupingInterval = Duration.fromObject({
  minutes: 5
}).toMillis()

/**
 * This is the standard deduping interval that should be used for resoures which are
 * expected to be updated frequently, and is not desirable for them to be stale, but
 * will lead to no breaking behaviour however.
 */
export const ShortDedupingInterval = Duration.fromObject({
  minutes: 1
}).toMillis()

export const AppSWRConfig = ({ children, config: overrideConfig }: Props) => {
  return (
    <SWRConfig value={{ ...SWRConfigValue, ...overrideConfig }}>
      {children}
    </SWRConfig>
  )
}
