/** @jsxImportSource @emotion/react */
import { PropsWithChildren, useState } from 'react'
import styled from '@emotion/styled'
import MobileNavbar from 'shared/components/organisms/mobile-navbar/MobileNavbar'
import { Sidebar } from 'shared/components/organisms/sidebar'
import { useMobileScreen } from 'shared/hooks/useMobileScreen'
import { SuspenseBoundary } from 'shared/screens/SuspenseBoundary'

const Layout = styled.div<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  display: 'grid',
  height: '100vh',
  width: '100vw',
  gap: theme.spacing(2),
  gridTemplateColumns: isMobile ? undefined : 'auto 1fr'
}))

export const SidebarLayout = ({ children }: PropsWithChildren<{}>) => {
  const isMobileScreen = useMobileScreen()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <div className="w-full">
      <div className="flex">
        <Layout isMobile={isMobileScreen}>
          <Sidebar
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={() => setIsDrawerOpen(v => !v)}
          />
          <main className={isMobileScreen ? 'main-mobile' : ''}>
            <SuspenseBoundary>
              <div className="inner-content">{children}</div>
            </SuspenseBoundary>
          </main>
          {isMobileScreen ? (
            <MobileNavbar
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={() => setIsDrawerOpen(v => !v)}
            />
          ) : null}
        </Layout>
      </div>
    </div>
  )
}
