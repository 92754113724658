import qs from 'qs'

export type QueryStringParams = Record<string, any>

/**
 * Format parameters to the application default/common query string format.
 */
export function getQueryString(
  params: QueryStringParams,
  options?: qs.IStringifyOptions
) {
  return qs.stringify(params, {
    addQueryPrefix: true,
    encode: true,
    arrayFormat: 'brackets',
    skipNulls: true,
    filter: (prefix, value) => {
      if (prefix.includes('search') && !value) {
        return null
      }

      return value || undefined
    },
    ...options
  })
}
