import { useEffect, useState } from 'react'

export type Storage = Extract<keyof Window, `${string}Storage`>

function useStorage<T>(
  key: string | null,
  initialValue: T,
  storage: Storage = 'localStorage'
) {
  if (key) {
    const storageData = window[storage].getItem(key)

    if (storageData) {
      initialValue = JSON.parse(storageData)
    }
  }

  const [value, setValue] = useState<T>(initialValue)

  useEffect(() => {
    if (key !== null) {
      window[storage].setItem(key, JSON.stringify(value))
    }
  }, [value, key, storage])

  return [value, setValue] as const
}

export default useStorage
