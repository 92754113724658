import { ArrowLeftCircle } from 'react-feather'
import { NavLink } from 'react-router-dom'

import { useDocumentTitle } from '../hooks/document-title/useDocumentTitle'

type Props = {
  isLoading?: boolean
}

const NotFoundPage = ({ isLoading = false }: Props) => {
  useDocumentTitle('Page not found')

  // eslint-disable-next-line no-nested-ternary
  return isLoading ? null : (
    <div className="w-screen h-screen flex-center flex-col">
      <div className="text-xl color-white">Page not found</div>
      <NavLink to="/" className="menu-item mt-4">
        <ArrowLeftCircle size={24} /> <span>Home</span>
      </NavLink>
    </div>
  )
}

export default NotFoundPage
