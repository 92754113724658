import { ComponentProps } from 'react'
import logo from 'assets/images/logo.png'
import { usePlatformConfiguration } from 'services/platform-configuration.service'

type Props = ComponentProps<'img'>

export const Logo = (props: Props) => {
  const { data } = usePlatformConfiguration()

  return (
    <img
      width="50"
      style={{ margin: '1rem 0' }}
      src={data.logo || logo}
      alt="Comapny logo"
      {...props}
    />
  )
}
