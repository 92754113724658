import { usePlatformConfiguration } from 'services/platform-configuration.service'
import { DataTableColumns } from 'shared/components/organisms/data-table'

/**
 * Transform data table columns to valid export columns.
 */
export function useToExportColumns() {
  const { getHeader } = usePlatformConfiguration()

  return (cols: DataTableColumns<any>): Record<string, string> => {
    const exp: Record<string, string> = {}

    for (const col of cols) {
      exp[col.field] = getHeader(col) || col.field
    }

    return exp
  }
}
