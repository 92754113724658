import { createRoot } from 'react-dom/client'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { App } from 'App'
import { PublicMediaImpression } from 'business/marketing/media/PublicMediaImpression'
import { Kernel } from 'Kernel'

import './assets/styles/base.scss'

createRoot(document.getElementById('root')!).render(
  <Kernel>
    <Routes>
      <Route
        path="/impressions/media/:token/:affiliate"
        element={<PublicMediaImpression />}
      />
      <Route
        path="*"
        element={
          <>
            <App />
            <ToastContainer
              position="bottom-center"
              autoClose={3000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
              theme="dark"
              closeButton={false}
            />
          </>
        }
      />
    </Routes>
  </Kernel>
)
