import { ArrowLeftCircle } from 'react-feather'
import { NavLink, useLocation } from 'react-router-dom'

import { useDocumentTitle } from '../hooks/document-title/useDocumentTitle'

type Props = {
  isLoading?: boolean
}

const NoAccessPermissionPage = ({ isLoading = false }: Props) => {
  useDocumentTitle('Forbidden')

  const { pathname } = useLocation()

  return isLoading ? null : (
    <div className="w-screen h-screen flex-center flex-col">
      <div className="text-xl color-white">
        You do not have permission to access this page.
      </div>
      {pathname !== '/' ? (
        <NavLink to="/" className="menu-item mt-4">
          <ArrowLeftCircle size={24} /> <span>Home</span>
        </NavLink>
      ) : null}
    </div>
  )
}

export default NoAccessPermissionPage
