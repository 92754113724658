import { useAuth } from 'services/auth.service'

import Role, { RoleSlug } from '../../../../services/types/Role'

type Has = (...roles: RoleSlug[]) => boolean

/**
 * Check whether user has role(s).
 *
 * @param user If user is given, it will be checked for the presence of roles; otherwise the currently authenticated user is used.
 */
const useRoles = (user?: { roles?: Role[] }): Has => {
  const currentUserRoles = useAuth().maybeUser?.roles
  const availableRoles = user?.roles || currentUserRoles || []

  return (...roles) =>
    availableRoles.some(({ slug }) => roles.some(role => slug === role))
}

export default useRoles
