import { forwardRef } from 'react'
import { Button, ButtonProps } from '@coddess-development/coddess-ui'
import { useFormikContext } from 'formik'

type OwnProps = {
  /**
   * Whether button is disabled on invalid form.
   */
  disableOnInvalid?: boolean
  submit?: boolean
}

export type FormSubmitButtonProps = OwnProps &
  Omit<ButtonProps, 'label' | keyof OwnProps>

export const FormSubmitButton = forwardRef<
  HTMLButtonElement,
  FormSubmitButtonProps
>(
  (
    {
      children = '',
      disabled,
      disableOnInvalid,
      submit = true,
      onClick,
      variant = 'primary',
      ...props
    },
    ref
  ) => {
    const { isSubmitting, isValid, submitForm } = useFormikContext()

    return (
      <Button
        {...props}
        variant={variant}
        type={submit ? 'submit' : 'button'}
        onClick={() => {
          if (!submit) {
            submitForm()
          }

          onClick?.()
        }}
        disabled={disabled || (disableOnInvalid && !isValid) || isSubmitting}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)
