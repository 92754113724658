"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealStatus = exports.RsTypeTexts = exports.RsType = void 0;
/**
 * The type of activity value on which the revenue share is calculated.
 */
exports.RsType = {
    NGR: 'ngr',
    GGR: 'ggr',
    Bets: 'bets'
};
exports.RsTypeTexts = {
    [exports.RsType.NGR]: 'NGR',
    [exports.RsType.GGR]: 'GGR',
    [exports.RsType.Bets]: 'Turnover (Bets)'
};
exports.DealStatus = {
    /**
     * `active` -> A deal that is active for a deal group, and has not been archived.
     */
    Active: 'active',
    /**
     * `pending` -> A deal that is to be activated in the future on a given starting date.
     */
    Pending: 'pending',
    /**
     * `archived` -> A deal that was active, but is currently suspended.
     * If there is no other deal to become currently active, then the whole deal group will not be applicable.
     */
    Archived: 'archived',
    /**
     * `edited` -> A deal that was active for a deal group, but was edited into a new one.
     */
    Edited: 'edited'
};
