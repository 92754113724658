"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskStatusDisplayValues = exports.TaskStatus = void 0;
exports.TaskStatus = {
    Open: 'open',
    InProgress: 'in progress',
    Stuck: 'stuck',
    Done: 'done'
};
exports.TaskStatusDisplayValues = {
    Open: 'Open',
    InProgress: 'In Progress',
    Stuck: 'Stuck',
    Done: 'Done'
};
