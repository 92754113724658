import { ReactNode } from 'react'
import { ThemeProvider as CoddessThemeProvider } from '@coddess-development/coddess-ui'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { StyledEngineProvider } from '@mui/system'
import { theme } from 'assets/styles/theme'

type Props = {
  children: ReactNode
}

export const AppThemeProvider = ({ children }: Props) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CoddessThemeProvider theme={theme}>{children}</CoddessThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}
