import { Button } from '@coddess-development/coddess-ui'
import { ErrorBase, ErrorBaseProps } from 'shared/screens/errors/ErrorBase'

type Props = Pick<ErrorBaseProps, 'fullScreen'>

export const UnknownError = ({ fullScreen }: Props) => {
  return (
    <ErrorBase
      fullScreen={fullScreen}
      title="Something Went Wrong"
      message="An unknown error has occured. If this issue persists, please report it to the support team."
    >
      <Button variant="primary" onClick={() => window.location.reload()}>
        Reload
      </Button>
    </ErrorBase>
  )
}
