import { PaymentStatus } from 'services/types/Financials'

export const BalanceCorrectionStatuses: PaymentStatus[] = ['Debit', 'Credit']

export const AdminFinancialsPaymentStatuses = {
  Checklist: ['pending', 'rejected'],
  Management: ['approved'],
  Accounting: []
} as const

export const AffiliateFinancialsPaymentStatuses = {
  PaymentMonitoring: ['pending', 'rejected', 'approved'],
  Accounting: ['sent', 'confirmed', 'credit', 'debit']
} as const
