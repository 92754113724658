"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentRejectReason = exports.BalanceCorrectionReason = void 0;
exports.BalanceCorrectionReason = {
    'fix-fee': 'Fix Fee',
    'deal-correction': 'Deal Correction',
    'fraud-abuse': 'Fraud/Abuse',
    other: 'Other'
};
exports.PaymentRejectReason = {
    'invoice-issue': 'Invoice Issue',
    'bank-issue': 'Bank Issue',
    'amount-mismatch': 'Amount Mismatch',
    'amount-dispute': 'Amount Dispute'
};
